export const
    // Main Colors
    PRIMARY_COLOR = '#AD976E',
    SECONDARY_COLOR = '#32363E',
    TERTIARY_COLOR = '#86aeb3',

    // Normal Colors
    WHITE = '#FFF',
    WHITE_T = '#ffffffAD',
    BLACK = '#000',
    DARK = '#333',
    GRAY = '#E0E0E0',
    GRAY_LIGHT = 'rgb(242, 242, 242)',

    //Important Colors
    SUCCESS_COLOR = '#8ce99a',
    ERROR_COLOR = '#ffa8a8',
    WARNING_COLOR = '#FF9800',
    INFO_COLOR = '#a5d8ff',
    PROCESS_COLOR = '#BBDEFB',

    //PROCESS COLOR
    CANCEL = '#fa5252',
    PROCESS = '#74c0fc',
    CONFIRM = '#51cf66',
    COMPLETE = '#51cf66',
    GOLDEN = '#D1B000';

