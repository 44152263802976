import {getAPIEndpointUrl} from "./ServerRequestModifier";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const toUpperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function pxToRem(value) {
    return `${value / 16}rem`;
}


export const getTotalPrice = (data) => {
    return data.reduce((total, item)=> {return (item.price * item.quantity) + total}, 0);
}

export const getSubTotalPrice = (data) => {
    return data.reduce((total, item)=> {return (item.price * item.quantity) + total}, 0);
}

export const getOrderSpending = (data, type) => {
    return data.reduce((total, item)=> {return item.orderStatus === type ? item.total + total : 0+total}, 0);
}

export const getTypeCount = (data, type) => {
    return data.reduce((total, item)=> {return item.orderStatus === type ? 1 + total : 0+total}, 0);
}

export const isToday = (date) => {
    const today = new Date()
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear();
}

export const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (yesterday.toDateString() === date.toDateString()) {
        return true;
    }

    return false;
}

export const isThisWeek = (date) => {
    const today = new Date();

    const msBetweenDates = Math.abs(date.getTime() - today.getTime());
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

    if (daysBetweenDates < 7) {
        return true;
    }

    return false;
}

export const getLocalReplaced = (file) => {
    return file.replace('http://localhost:3003', '')
}

export function addThousandSeparator(numberString) {
    // Remove any non-digit characters from the string
    const cleanedNumberString = numberString.replace(/[^\d.-]/g, '');

    // Convert the cleaned string to a number
    let number = parseFloat(cleanedNumberString);

    // Check if the number is valid
    if (isNaN(number)) {
        return numberString; // Return original string if not a valid number
    }

    // Split the number into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');

    // Add thousand separators to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Remove trailing zeros in decimal part if present
    parts[1] = parts[1] && parseInt(parts[1], 10) !== 0 ? parts[1] : '';

    // Join the parts back together
    return parts.join(parts[1] ? '.' : ''); // Only add decimal point if there's a non-zero decimal part
}