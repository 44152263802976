import React, {useEffect} from 'react';
import {Button, Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {AdminsContext} from "../../contexts/AdminsContext";
import AddAdmin from "./components/AddAdmin";
import {AuthenticationContext} from "../../contexts/AuthContext";
import Page from "../../general_components/Page";
import StackCustom from "../../general_components/StackCustom";
import AdminCard from "./components/AdminCard";
import {PRIMARY_COLOR} from "../../constants/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({

})

export default function Admins()
{
    const classes = useStyles();
    //Context
    const adminsContext = React.useContext(AdminsContext);
    const { admins, handleDelete,} = adminsContext;
    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const [open, setOpen] = React.useState(false);


    return (
        <Page title="Riviera SkyGarden | Admins">
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: '100%', minWidth: '100%',}}>
                <StackCustom text="Admins"
                    child={
                       !(userAccount?.permissions?.includes('ADD_ADMIN')) ? null :
                            <IconButton style={{backgroundColor: PRIMARY_COLOR, color: '#fff', marginLeft: 6}}
                                    variant={'contained'}
                                    onClick={() => setOpen(true)}>
                            <AddRoundedIcon size={18}/>
                        </IconButton>
                    }
                />
                    <Grid container spacing={5}>
                        {
                            admins.map((item, index) => (
                                <Grid item xs={12} sm={6} md={3}>
                                    <AdminCard data={item} index={index} handleDelete={handleDelete}/>
                                </Grid>
                            ))
                        }
                    </Grid>
            </div>
            <AddAdmin open={open} setOpen={setOpen}/>
        </Page>
    );
}
