import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {SECONDARY_COLOR} from "../constants/Colors";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Drawer, IconButton, Typography} from "@mui/material";

export default function MobileList({title, item, children}) {

    const [openList, setOpenList] = useState(false);

    useEffect(() => {
        setOpenList(false)
    }, [item]);

    return (
        <>
            <IconButton
                onClick={() => setOpenList(true)}
                sx={{ ml: 2, color: SECONDARY_COLOR, maxHeight: 20, minHeight: 40, display: { md: 'none' } }}
            >
                <MenuIcon/>
            </IconButton>
            <Drawer
                anchor={'right'}
                open={openList}
                onClose={() => setOpenList(false)}
            >
               <div style={{position: 'relative', minWidth: '300px'}}>
                   <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                       <Typography
                           variant="h5"
                           sx={{fontWeight: 700, fontFamily: 'MontserratRegular !important', marginTop: 2, marginLeft: 2, marginBottom: 2}}
                       >
                           {title}
                       </Typography>
                       <IconButton
                           onClick={() => setOpenList(false)}
                           sx={{ ml: 2, color: SECONDARY_COLOR, backgroundColor: 'transparent',
                               maxHeight: 40, minHeight: 40, display: { md: 'none' }}}
                       >
                           <CloseIcon/>
                       </IconButton>
                   </div>
                   {children}
               </div>
            </Drawer>
        </>
    );
}