import React, {useContext, useEffect, useState} from 'react';
import Page from "../../general_components/Page";
import StackCustom from "../../general_components/StackCustom";
import ChartButtons from "./components/ChartButtons";
import {pento4BedImage} from "./components/ChartData";
import NexusButtons from "./components/NexusButtons";
import Chart from "./components/Chart";
import {PRIMARY_COLOR} from "../../constants/Colors";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import DimensionButtons from "./components/DimensionButtons";
import Dimension from "./components/Dimension";
import {ChartContext} from "../../contexts/ChartContext";
import PentoButtons from "./components/PentoButtons";
import USDService from "../../services/USDService";

export default function Home() {

    const chartContext = useContext(ChartContext);
    const { pentoData, data, setData, usdRate } = chartContext

    const [selected, setSelected] = useState('PENTO'),
        [nexusSelected, setNexusSelected] = useState('3 Bed'),
        [pentoSelected, setPentoSelected] = useState('4 Bed');

    const [title, setTitle] = useState('5 Bedrooms - 21th Floor'),
        [image, setImage] = useState(pento4BedImage);

    return (
        <Page title="Riviera SkyGarden | Home" style={{paddingBottom: '6vh'}}>
            <StackCustom text="AVAILABILITY CHART"/>
            <Grid container style={{minWidth: 700}}>
                <Grid item xs={12} sm={12} md={7}>
                    <ChartButtons
                        selected={selected}
                        setSelected={setSelected}
                        setData={setData}
                        setImage={setImage}
                    />
                    {
                        selected === 'NEXUS' ?
                            <NexusButtons
                                selected={nexusSelected}
                                setSelected={setNexusSelected}
                                setData={setData}
                                setImage={setImage}
                            /> :
                            selected === 'PENTO' ?
                                <PentoButtons
                                    selected={pentoSelected}
                                    setSelected={setPentoSelected}
                                    setData={setData}
                                    setImage={setImage}
                                />
                                : null
                    }
                    <Chart data={data === null ? pentoData : data} selected={selected}
                           setImage={setImage} setTitle={setTitle}
                           nexusSelected={nexusSelected}
                           pentoSelected={pentoSelected}
                           USDRate={usdRate}
                    />
                    {
                        selected === 'SKYEXCHANGE' ?
                            <Typography
                                variant='body2'
                                sx={[{lineHeight: 1, color: PRIMARY_COLOR,
                                    fontFamily: 'MontserratLight !important',
                                }]}
                                pt={4}
                            >
                                *Shell & Core Offices. Furniture and layout for display purposes only.
                            </Typography>
                            : null
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={5}
                      pl={{xs: 1, sm: 4, md: 4}}
                      pr={{xs: 1, sm: 4, md: 4}}
                >
                    <DimensionButtons/>
                    <Dimension
                        title={title}
                        image={image}
                        appointmentView={selected === 'PENTO' && (pentoSelected === 'Duplex' || pentoSelected === 'Penthouse')}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
