import React, {createContext, useState} from 'react';
import InterestService from "../services/InterestService";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";


export const InterestContext = createContext();

export default function InterestContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [showDetails, setShowDetails] = useState(false),
        [item, setItem] = useState(null);

    const [interestData, setInterestData] = useState([]);

    const [openDelete, setOpenDelete] = useState(false),
        [interestId, setInterestId] = useState('')

    const getInterestsData = () => {
        setOpen(true);
        InterestService.getInterests().then(res => {
            setOpen(false);
            if(res.data.success){
                setInterestData(arr => [...res.data.data]);
            }else {
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false);
        }).finally(() => {
            setOpen(false);
        })
    }

    const handleDelete = () => {
        setOpen(true)
        InterestService.deleteInterest(interestId).then(res => {
            if(res.data.success){
                setInterestData(interestData.filter((item) => item.interestId !== interestId))
                setOpenDelete(false);
                setInterestId('')
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                    setItem(null)
                    setShowDetails(false)
                })
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    const updateInterest = (id, update) => {
        setOpen(true)
        if(interestData.length !== 0) {
            setInterestData(
                interestData.map(item => {
                    if(item.interestId === id){
                        setOpen(false)
                        // setConfirm(true);
                        // setTimeout(() => {
                        //     setConfirm(false);
                        // }, 2000);
                        setItem({...item, isRead: true, ...update})
                        return {...item, isRead: true, ...update}
                    }else {
                        setOpen(false)
                        return item;
                    }
                }));
            setOpen(false)
        }
        setOpen(false)
    }

    return (
        <InterestContext.Provider value={{
            showDetails, setShowDetails, item, setItem, interestData, setInterestData,
            getInterestsData, openDelete, setOpenDelete, handleDelete, interestId, setInterestId,
            updateInterest
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </InterestContext.Provider>
    )
}