import React, {useContext} from 'react';
import CustomButton from "../../../general_components/LoadingButton";
import {PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR, WHITE} from "../../../constants/Colors";
import useResponsive from "../../../hooks/useResponsive";
import { nexusThreeBedImage, pento4BedImage, skyExchangeImage } from "./ChartData";
import {ChartContext} from "../../../contexts/ChartContext";

export default function ChartButtons({selected, setSelected, setData, setImage}) {

    const isMobile = useResponsive('down', 'md');

    const chartContext = useContext(ChartContext);
    const { pentoData, nexusThreeBedData, skyExchangeData} = chartContext

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <CustomButton
                text={'PENTO'}
                style={{
                    backgroundColor: selected === 'PENTO' ? SECONDARY_COLOR : WHITE,
                    boxShadow: 'none',
                    borderRadius: 0,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  PRIMARY_COLOR,
                    },
                    color: selected === 'PENTO' ? PRIMARY_COLOR : PRIMARY_COLOR,
                    fontSize: 20,
                    border: `1px solid ${SECONDARY_COLOR}`,
                    width: isMobile ? 200 : 200
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200
                }}
                onClick={() => {
                    setSelected('PENTO')
                    setData(pentoData);
                    setImage(pento4BedImage)
                }}
            />
            <CustomButton
                text={'NEXUS'}
                style={{
                    backgroundColor: selected === 'NEXUS' ? SECONDARY_COLOR : WHITE,
                    boxShadow: 'none',
                    borderRadius: 0,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  PRIMARY_COLOR,
                    },
                    color: selected === 'NEXUS' ? TERTIARY_COLOR : TERTIARY_COLOR,
                    fontSize: 20,
                    border: `1px solid ${SECONDARY_COLOR}`,
                    width: isMobile ? 200 : 200
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200
                }}
                onClick={() => {
                    setSelected('NEXUS')
                    setData(nexusThreeBedData)
                    setImage(nexusThreeBedImage)
                }}
            />
            <CustomButton
                text={'SKYEXCHANGE'}
                style={{
                    backgroundColor: selected === 'SKYEXCHANGE' ? SECONDARY_COLOR : WHITE,
                    boxShadow: 'none',
                    borderRadius: 0,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  PRIMARY_COLOR,
                    },
                    color: selected === 'SKYEXCHANGE' ? PRIMARY_COLOR : SECONDARY_COLOR,
                    fontSize: 20,
                    border: `1px solid ${SECONDARY_COLOR}`,
                    width: isMobile ? 200 : 200
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200
                }}
                onClick={() => {
                    setSelected('SKYEXCHANGE')
                    setData(skyExchangeData)
                    setImage(skyExchangeImage)
                }}
            />
        </div>
    );
}
