import React from 'react';
import {ERROR_COLOR, INFO_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, SUCCESS_COLOR, WHITE} from "../../../constants/Colors";
import CustomButton from "../../../general_components/LoadingButton";
import {makeStyles} from "@mui/styles";
import useResponsive from "../../../hooks/useResponsive";


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export default function DimensionButtons() {

    const classes = useStyles();
    const isMobile = useResponsive('down', 'md');

    return (
        <div className={classes.container}>
            <CustomButton
                text={'AVAILABLE'}
                style={{
                    backgroundColor: SUCCESS_COLOR,
                    boxShadow: 'none',
                    borderRadius: 30,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  SUCCESS_COLOR,
                    },
                    color: WHITE,
                    fontSize: 16,
                    width: 150,
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200
                }}
            />
            <CustomButton
                text={'RESERVED'}
                style={{
                    backgroundColor: INFO_COLOR,
                    boxShadow: 'none',
                    borderRadius: 30,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  INFO_COLOR,
                    },
                    color: WHITE,
                    fontSize: 16,
                    width: 150,
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200,
                    marginLeft: 2, marginRight: 2,
                }}
            />
            <CustomButton
                text={'SOLD'}
                style={{
                    backgroundColor: ERROR_COLOR,
                    boxShadow: 'none',
                    borderRadius: 30,
                    fontWeight: 'normal',
                    height: 50,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor:  ERROR_COLOR,
                    },
                    color: WHITE,
                    fontSize: 16,
                    width: 150,
                }}
                sx={{
                    zIndex: 100,
                    fontFamily: 'MontserratRegular !important',
                    fontWeight: 200
                }}
            />
        </div>
    );
}
