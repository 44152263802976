import React, {useEffect, useState} from 'react';
import Page from "../../general_components/Page";
import {Container, Divider, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CustomFilter from "../../general_components/CustomFilter";
import {AgentContext} from "../../contexts/AgentContext";
import AgentListItem from "./components/AgentListItem";
import StackCustom from "../../general_components/StackCustom";
import AgentsRequestsDetails from "./components/AgentsRequestsDetails";
import GroupButton from "./components/GroupButton";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ConfirmationDialog from "../../general_components/ConfirmationDialog";
import {AuthenticationContext} from "../../contexts/AuthContext";
import useResponsive from "../../hooks/useResponsive";
import MobileList from "../../general_components/MobileList";


const useStyles = makeStyles({
    gridContainer: {
        display: 'flex',
        maxWidth: '100%',
        minWidth: '100%',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    detailsContainer: {
        maxHeight: '82vh',
        overflowY: 'auto',
        paddingLeft: 0,
        paddingRight: 0
    }
})

export default function AgentsRequests() {

    const classes = useStyles();
    const agentContext = React.useContext(AgentContext),
        {getAgentRequestsData, updateRequest, showDetails, setShowDetails,
            item, setItem, agentRequestData, openDelete, setOpenDelete, handleDelete,
            requestId, setRequestId,
        } = agentContext;

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    useEffect(() => {
        getAgentRequestsData();
    }, []);

    const isMobile = useResponsive('down', 'md');

    const [filterValue, setFilterValue] = useState({
        status: 'All', date: 'All Time'
    });

    return (
        <Page title="Riviera SkyGarden | Home" style={{paddingBottom: '0vh'}}>
            <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', maxWidth: '100%', minWidth: '100%',}}>
                <div className={classes.listContainer} style={{width: isMobile ? '100%' : '40%'}}>
                    <Stack direction="row" alignItems="center" mb={0}>
                        <Typography
                            variant="h5"
                            sx={{fontWeight: 700, fontFamily: 'MontserratRegular !important',
                                flexGrow: 1, display: 'flex', paddingRight: 2}}
                        >
                            Agents Requests
                        </Typography>
                        <CustomFilter filterValue={filterValue} onValueChange={setFilterValue}/>
                        {
                            !isMobile ? null :
                                <MobileList title={'Brochure Requests List'} item={item}>
                                    <AgentListItem
                                        setShowDetails={setShowDetails}
                                        data={agentRequestData}
                                        setItem={setItem}
                                        filterValue={filterValue}
                                        updateRequest={updateRequest}
                                    />
                                </MobileList>
                        }
                    </Stack>
                    <Typography variant={'subtitle2'} color={'textSecondary'}>
                        {`${filterValue.status} Status, ${filterValue.date}`}
                    </Typography>
                    {
                        isMobile ? null :
                            <AgentListItem
                                setShowDetails={setShowDetails}
                                data={agentRequestData}
                                setItem={setItem}
                                filterValue={filterValue}
                                updateRequest={updateRequest}
                            />
                    }
                </div>
                {
                    isMobile ? null :
                        <Divider
                            orientation="vertical"
                            sx={{height: '90vh',}}
                        />
                }
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginLeft: isMobile ? 0 : 10,
                    paddingBottom: 5, paddingLeft: isMobile ? 0 : 20, marginTop: isMobile ? 20 : 0}}>                    {
                        !showDetails ? null :
                            <div>
                                <StackCustom
                                    text="Details"
                                    child={ userAccount?.permissions?.includes('UPDATE_AGENT_REQUESTS') ?
                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <GroupButton item={item}
                                                             updateRequest={updateRequest}/>
                                                <Tooltip title={"Delete this Request"}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setRequestId(item.requestId)
                                                            setOpenDelete(true)
                                                        }}
                                                        sx={{marginLeft: 10 }}
                                                    >
                                                        <DeleteRoundedIcon sx={{fontSize: 20}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div> : null
                                    }
                                />
                                <div className={classes.detailsContainer}>
                                    <AgentsRequestsDetails item={item} setItem={setItem}/>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <ConfirmationDialog
                loading={false}
                open={openDelete}
                close={setOpenDelete}
                confirm={handleDelete}
                title="Confirmation"
                message="Are you sure you want to delete this?"
                cancelBtnText="Cancel"
                confirmBtnText="Delete"
            />
        </Page>
    );
}
