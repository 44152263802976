import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class ChartService {

    getFloorPlan() {
        return http.get(addTokenToURL("/admin/floor-plan"));
    }

    updateFloorPlan(data) {
        return http.put("/admin/floor-plan", addTokenToData(data));
    }

}

export default new ChartService();
