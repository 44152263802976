import React, {useEffect, useState} from 'react';
import AdminService from "../services/AdminService";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";

export const AdminsContext = React.createContext();

export default function AdminsContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [admins, setAdmins] = useState([]);

    //Loading
    const [load, setLoad] = useState(false);
    //Confirmation Open-Close
    const [openConfirmation, setOpenConfirmation] = useState(false);
    //Which Item
    const [index, setIndex] = useState(-1)
    //Add Admins
    const [openAddAdmins, setOpenAddAdmins] = useState(false);
    const [name, setName] = useState(''),
        [email, setEmail] = useState(''),
        [password, setPassword] = useState(''),
        [logo, setLogo] = useState(''),
        [role, setRole] = useState(null),
        [permissions, setPermissions] = useState([]);


    useEffect(() => {
        setOpen(true)
        AdminService.getAdmins().then(res => {
            if(res.data.success){
                setAdmins(res.data.data);
                setOpen(false)
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }, [])

    const handleDelete = (id) => {
        setOpen(true)
        AdminService.deleteAdmin(id).then(res => {
            if(res.data.success){
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                })
                //    Clearing current fields
                setAdmins(admins.filter((items) => items.userId !== id ))
            } else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    const handleAddData = (data) => {
        setOpen(true);
        AdminService.addAdmin(data).then(res => {
            if(res.data.success){
                setAdmins([...admins, res.data.data]);
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                })
            //    Clearing current fields
                setName('');
                setEmail('');
                setPassword('');
                setRole(null);
                setPermissions([]);

            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    const updateAdmin = (userId, data) => {
        setAdmins(prevAdmins => prevAdmins.map(value => {
            if(userId === value.userId){
                return {...value, ...data};
            }
            return value;
        }))
    }

    return (
        <AdminsContext.Provider
            value={{ admins, setAdmins, openConfirmation, setOpenConfirmation, handleDelete, index, setIndex,
                load, setLoad, openAddAdmins, setOpenAddAdmins, logo, setLogo, handleAddData,
                name, setName, email, setEmail, password, setPassword, role, setRole,
                permissions, setPermissions, updateAdmin
            }}
        >
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </AdminsContext.Provider>
    );
}
