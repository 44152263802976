import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {WHITE} from "../constants/Colors";
import PendingIcon from '@mui/icons-material/Pending';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CollectionsIcon from '@mui/icons-material/Collections';

const sidebarConfig = [
  {
    title: 'Home',
    path: '/home',
    icon: <HomeRoundedIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Interest',
    path: '/interest',
    icon: <SupportAgentIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Agents Requests',
    path: '/agents-requests',
    icon: <PendingIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Agents',
    path: '/agents',
    icon: <HowToRegIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Brochure Requests',
    path: '/brochure-requests',
    icon: <ImportContactsIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Gallery',
    path: '/gallery',
    icon: <CollectionsIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Work In Progress',
    path: '/work-in-progress',
    icon: <EngineeringIcon sx={{color: WHITE}}/>
  },
  {
    title: 'Admins',
    path: '/admins',
    icon: <AdminPanelSettingsIcon sx={{color: WHITE}}/>
  },
];

export default sidebarConfig;
