import React from 'react';
import ChartHeader from "./ChartHeader";
import ChartListData from "./ChartListData";

export default function Chart({ data, selected, setImage, setTitle, nexusSelected, pentoSelected, USDRate }) {

    const checkPento = (item) => {
        if(pentoSelected === 'Duplex') {
            if(item.unit.includes('DP')) {
                return true
            }
        }
        else if(pentoSelected === 'Penthouse') {
            if(item.floorName.includes('PH')) {
                return true
            }
        }
        else if(pentoSelected === '4 Bed') {
            if(item.rooms === 4) {
                return true
            }
        }
        else if(pentoSelected === '5 Bed') {
            if(item.rooms === 5) {
                return true
            }
        }
        return false
    }

    return (
        <div>
            <ChartHeader selected={selected} USDRate={USDRate}/>
            {
                data.map((item, index) => {
                    return (selected === 'PENTO' ? checkPento(item) : true) ? (
                        <ChartListData
                            item={item} selected={selected}
                            setImage={setImage} setTitle={setTitle}
                            nexusSelected={nexusSelected}
                            pentoSelected={pentoSelected}
                            USDRate={USDRate}
                        />
                    ) : null
                })
            }
        </div>
    );
}
