import React, {useEffect} from 'react';
import Page from "../../general_components/Page";
import {Divider, IconButton, Stack, Typography, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import BrochureRequestsList from "./components/BrochureRequestsList";
import BrochureRequestDetails from "./components/BrochureRequestDetails";
import StackCustom from "../../general_components/StackCustom";
import {BrochureContext} from "../../contexts/BrochureContext";
import useResponsive from "../../hooks/useResponsive";
import MobileList from "../../general_components/MobileList";

const useStyles = makeStyles({
    gridContainer: {
        display: 'flex',
        maxWidth: '100%',
        minWidth: '100%',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%'
    },
    detailsContainer: {
        maxHeight: '82vh',
        overflowY: 'auto',
        paddingLeft: 0,
        paddingRight: 0
    }
})

export default function BrochureRequests() {

    const classes = useStyles();

    const brochureContext = React.useContext(BrochureContext),
        {brochureRequests, item, setItem, getBrochureRequests, showDetails, setShowDetails, updateBrochureRequest} = brochureContext;

    const isMobile = useResponsive('down', 'md');

    useEffect(() => {
        getBrochureRequests();
    }, []);

    return (
        <Page title="Riviera SkyGarden | Brochure Requests" style={{paddingBottom: '0vh'}}>
            <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', maxWidth: '100%', minWidth: '100%',}}>
                <div className={classes.listContainer} style={{width: isMobile ? '100%' : '40%'}}>
                    <Stack direction="row" alignItems="center" mb={0}>
                        <Typography
                            variant="h5"
                            sx={{fontWeight: 700, fontFamily: 'MontserratRegular !important',}}
                        >
                            Brochure Requests List
                        </Typography>
                        {
                            !isMobile ? null :
                                <MobileList title={'Brochure Requests List'} item={item}>
                                    <BrochureRequestsList
                                        setShowDetails={setShowDetails}
                                        data={brochureRequests}
                                        setItem={setItem}
                                        updateBrochureRequest={updateBrochureRequest}
                                    />
                                </MobileList>
                        }
                    </Stack>
                    {
                        isMobile ? null :
                            <BrochureRequestsList
                                setShowDetails={setShowDetails}
                                data={brochureRequests}
                                setItem={setItem}
                                updateBrochureRequest={updateBrochureRequest}
                            />
                    }
                </div>
                {
                    isMobile ? null :
                        <Divider
                            orientation="vertical"
                            sx={{height: '90vh',}}
                        />
                }
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginLeft: isMobile ? 0 : 10,
                    paddingBottom: 5, paddingLeft: isMobile ? 0 : 20, marginTop: isMobile ? 20 : 0}}>                    {
                        !showDetails ? null :
                            <div>
                                <StackCustom
                                    text="Details"
                                    child={null}
                                />
                                <div className={classes.detailsContainer}>
                                    <BrochureRequestDetails item={item}/>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </Page>
    );
}
