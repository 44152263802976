import React from 'react';
import {Avatar} from "@mui/material";

function CustomAvatar({avatar, name, imageStyle, alphaImageStyle, handleClick, child}) {

    return (
        <>
            {
                avatar === null ?
                    <Avatar alt={name}
                            sx={alphaImageStyle}
                            onClick={() => handleClick()}
                    >
                        {name[0]}
                    </Avatar>
                    :
                    <Avatar alt={name} src={avatar} onClick={() => handleClick()}
                            sx={imageStyle}>
                        {child}
                    </Avatar>
            }
        </>
    );
}

export default CustomAvatar;