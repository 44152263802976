import React, {useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton, Tooltip, Typography} from "@mui/material";
import {PRIMARY_COLOR, WHITE} from "../../../constants/Colors";
import DialogContent from "@mui/material/DialogContent";
import CustomTextField from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {ChartContext} from "../../../contexts/ChartContext";
import USDService from "../../../services/USDService";
import EditIcon from "@mui/icons-material/Edit";

export default function UpdateUSDRate(props) {

    const chartContext = React.useContext(ChartContext);
    const { usdRate, setUsdRate } = chartContext

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValue(usdRate.usdRate)
    }, [usdRate]);

    const onSubmit = async (e) => {
        e.preventDefault();
        USDService.editUSDRate({usdRate: value}).then(response => {
            if(response.data.success){
                setUsdRate({usdRate: value});
                setOpen(false);
            }
        }).catch(() => alert("Something went wrong!"));
    }

    return (
        <>
            <Tooltip title={"Edit USD Rate"}>
                <IconButton
                    onClick={() => {
                        setOpen(true)
                    }}
                    sx={{zIndex: 1000,
                        position: 'absolute',
                        top: 0, right: 0
                    }}
                >
                    <EditIcon style={{fontSize: 16, color: '#fff'}}/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm'>
                <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600', flexGrow: 1}}>
                        Edit USD Rate
                    </Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 0}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            autoFocus={true}
                            required
                            disabled={false}
                            value={value}
                            onValueChange={setValue}
                            labelName={'USD Rate'}
                            style={{marginBottom: 25}}
                        />
                        <LoadingButton
                            type={'submit'} loading={false} text={'Update'}
                            sx={{color: WHITE}}
                        />
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

