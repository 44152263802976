import React, {useState} from 'react';
import {Grid, IconButton, Typography, Tooltip} from "@mui/material";
import {ERROR_COLOR, GRAY, INFO_COLOR, SECONDARY_COLOR, SUCCESS_COLOR} from "../../../constants/Colors";
import {makeStyles} from "@mui/styles";
import {pento4BedImage, pento5BedImage, E5A5B4A4B, skyExchangeImage} from "./ChartData";
import EditIcon from '@mui/icons-material/Edit';
import AddPrice from "./AddPrice";
import AddAvailability from "./AddAvailability";
import {AuthenticationContext} from "../../../contexts/AuthContext";
import {addThousandSeparator} from "../../../utilities/Others";
import {ChartContext} from "../../../contexts/ChartContext";

const useStyles = makeStyles((theme) => ({
    boxContainer: {
        backgroundColor: SECONDARY_COLOR,
        padding: 6,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    itemContainer: {
        backgroundColor: GRAY,
        width: '100%',
        padding: 3,
        //marginTop: 3,
        height: '100%'
    }
}));

const styles = {
    title: {
        fontWeight: 200,
        fontFamily: 'MontserratLight !important',
        textAlign: 'center'
    },
}

export default function ChartListData({item, selected, setTitle, setImage, nexusSelected, pentoSelected, USDRate}) {

    const classes = useStyles();
    const [onHoverUnit, setOnHoverUnit] = useState('');
    const [onHoverPrice, setOnHoverPrice] = useState('');
    const [openPrice, setOpenPrice] = useState(false),
        [currentPrice, setCurrentPrice] = useState('');
    const [openAvailability, setOpenAvailability] = useState(false),
        [currentStatus, setCurrentStatus] = useState('');

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const handelClickUnits = (data) => {
        if(selected === 'PENTO') {
            if(data.room === '4') {
                const title =  `${data.room} Bedrooms - ${data.floorName} Floor`
                setTitle(title)
                setImage(pento4BedImage)
            } else if(data.room === '5') {
                const title =  `${data.room} Bedrooms - ${data.floorName} Floor`
                setTitle(title)
                setImage(pento5BedImage)
            }
        } else if(selected === 'NEXUS') {
            if(nexusSelected === '3 Bed') {
                const title =  `3 Bedrooms - ${data.floorName} Floor`
                setTitle(title)
            } else if(nexusSelected === '4 Bed') {
                const title =  `4 Bedrooms - ${data.floorName} Floor`
                setTitle(title)
            }
        } else if(selected === 'SKYEXCHANGE') {
            const title =  `Office - ${data.floorName} Floor`
            setTitle(title)
            if(data.floorName === '5th' || data.floorName === '4th'){
                setImage(E5A5B4A4B);
            }else{
                setImage(skyExchangeImage);
            }
        }
    }

    const getMarginButton = () => {
        return (selected === 'PENTO' && pentoSelected === 'Duplex') || (selected === 'PENTO' && pentoSelected === 'Penthouse') ? 0 : 0.5;
    }

    return (
        <Grid container>
            <Grid item xs={2} sm={2} md={2}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
                  mb={0.5}
            >
                <div className={classes.itemContainer}>
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.floorName}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} mb={getMarginButton()}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
                  sx={{
                      cursor: 'pointer', userSelect: 'none'
                  }}
                  onClick={() => {handelClickUnits(item)}}
            >
                <div className={classes.itemContainer}
                     style={{
                         backgroundColor: item.unitStatus === 'SOLD' ? ERROR_COLOR :
                             item.unitStatus === 'RESERVED' ? INFO_COLOR :
                                 item.unitStatus === 'AVAILABLE' ? SUCCESS_COLOR : null,
                         position: 'relative'
                     }}
                     onMouseOver={() => setOnHoverUnit(item.floorName)}
                     onMouseLeave={() => setOnHoverUnit('')}
                >
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.unit}
                    </Typography>
                    {
                        !(userAccount?.permissions?.includes('EDIT_FLOOR_STATUS')) ? null :
                        onHoverUnit === item.floorName ?
                            <Tooltip title={"Edit Availability"}>
                                <IconButton
                                    onClick={() => {
                                        setCurrentStatus(item.unitStatus)
                                        setOpenAvailability(true)
                                    }}
                                    sx={{zIndex: 1000,
                                        position: 'absolute',
                                        top: 0, right: 0
                                    }}
                                >
                                    <EditIcon style={{fontSize: 16}}/>
                                </IconButton>
                            </Tooltip>
                            : null
                    }
                </div>
            </Grid>
            {
                !(selected === 'PENTO') ? null :
                    <Grid item xs={1.5} sm={1.5} md={1.5} mb={getMarginButton()}
                          pr={{xs: 0.5, sm: 0.5, md: 0.5}}
                    >
                        <div className={classes.itemContainer}>
                            <Typography variant='body2'
                                        sx={[styles.title, {color: SECONDARY_COLOR}]}
                            >
                                {item.rooms}
                            </Typography>
                        </div>
                    </Grid>
            }
            <Grid item xs={1} sm={1} md={1} mb={getMarginButton()}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
            >
                <div className={classes.itemContainer}>
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.netArea ? addThousandSeparator(item.netArea.toString()) : null}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1} mb={getMarginButton()}
                  pr={{xs: 0.5, sm: 0.5, md: 0.5}}
            >
                <div className={classes.itemContainer}>
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.grossArea ? addThousandSeparator(item.grossArea.toString()) : null}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} mb={getMarginButton()} pr={{xs: 0.5, sm: 0.5, md: 0.5}}>
                <div className={classes.itemContainer}
                     style={{position: 'relative'}}
                     onMouseOver={() => setOnHoverPrice(item.floorName)}
                     onMouseLeave={() => setOnHoverPrice('')}
                >
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.price ? addThousandSeparator(item.price.toString()) : null}
                    </Typography>
                    {
                        !(userAccount?.permissions?.includes('EDIT_FLOOR_PRICE')) ? null :
                        onHoverPrice === item.floorName ?
                            <Tooltip title={"Edit Price"}>
                                <IconButton
                                    onClick={() => {
                                        setOnHoverPrice(item.price)
                                        setOpenPrice(true);
                                    }}
                                    sx={{zIndex: 1000,
                                        position: 'absolute',
                                        top: 0, right: 0
                                    }}
                                >
                                    <EditIcon style={{fontSize: 16}}/>
                                </IconButton>
                            </Tooltip>
                            : null
                    }
                </div>
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} mb={getMarginButton()}>
                <div className={classes.itemContainer}
                     style={{position: 'relative'}}
                >
                    <Typography variant='body2'
                                sx={[styles.title, {color: SECONDARY_COLOR}]}
                    >
                        {item.price ?
                            addThousandSeparator((item.price/USDRate?.usdRate).toFixed(2).toString())
                            : null
                        }
                    </Typography>
                </div>
            </Grid>
            <AddPrice
                open={openPrice}
                handleClose={() => setOpenPrice(false)}
                value={item} selected={selected}
                currentPrice={currentPrice}
                setCurrentPrice={setCurrentPrice}
                nexusSelected={nexusSelected}
            />
            <AddAvailability
                open={openAvailability}
                handleClose={() => setOpenAvailability(false)}
                status={currentStatus}
                setStatus={setCurrentStatus}
                value={item} selected={selected}
                nexusSelected={nexusSelected}
            />
        </Grid>
    );
}
