import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class AdminService {

    getAdmins() {
        return http.get(addTokenToURL("/admin/admins"));
    }

    addAdmin(data) {
        return http.post("/admin/admin", addTokenToData(data));
    }

    editAdmin(data){
        return http.put(addTokenToURL("/admin/admin"), addTokenToData(data));
    }

    deleteAdmin(id){
        return http.delete(addTokenToURL("/admin/admin")+`&userId=${id}`);
    }

    changePassword(data) {
        return http.post("/admin/change-password", addTokenToData(data));
    }

}

export default new AdminService();
