import http from "../utilities/AxiosRequest";
import {addTokenToURL} from "../utilities/ServerRequestModifier";

class LoginRegistrationService {

    login(data) {
        return http.post("/admin/login", data);
    }
    authenticate() {
        return http.post(addTokenToURL("/admin/authenticate"));
    }
    
}

export default new LoginRegistrationService();
