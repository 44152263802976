import React, {useContext} from 'react';
import useResponsive from "../../../hooks/useResponsive";
import {ChartContext} from "../../../contexts/ChartContext";
import CustomButton from "../../../general_components/LoadingButton";
import {PRIMARY_COLOR, SECONDARY_COLOR, WHITE} from "../../../constants/Colors";
import {pento4BedImage, pento5BedImage} from "./ChartData";

export default function PentoButtons({selected, setSelected, setData, setImage}) {

    const isMobile = useResponsive('down', 'md');
    // const chartContext = useContext(ChartContext);
    // const { } = chartContext

    const styles = {
        button: {
            zIndex: 100,
            fontFamily: 'MontserratRegular !important',
            fontWeight: 200,
            boxShadow: 'none',
            borderRadius: 0,
            height: 50,
            fontSize: 20,
        }
    }

    return (
        <div style={{display: 'flex', marginTop: 20}}>
            <CustomButton
                text={'4 Bedrooms'}
                sx={[styles.button, {
                    backgroundColor: selected === '4 Bed' ? PRIMARY_COLOR : WHITE,
                    color: selected === '4 Bed' ? '#fff' : SECONDARY_COLOR,
                    border: `1px solid ${selected === '4 Bed' ? PRIMARY_COLOR : SECONDARY_COLOR}`,
                    width: isMobile ? 'auto' : 200,
                    marginRight: 1,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: selected === '4 Bed' ? PRIMARY_COLOR : WHITE,
                    },
                }]}
                onClick={() => {
                    setSelected('4 Bed')
                    // setData(nexusThreeBedData);
                    setImage(pento4BedImage)
                }}
            />
            <CustomButton
                text={'5 Bedrooms'}
                sx={[styles.button, {
                    backgroundColor: selected === '5 Bed' ? PRIMARY_COLOR : WHITE,
                    color: selected === '5 Bed' ? '#fff' : SECONDARY_COLOR,
                    border: `1px solid ${selected === '5 Bed' ? PRIMARY_COLOR : SECONDARY_COLOR}`,
                    width: isMobile ? 'auto' : 200,
                    marginRight: 1,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: selected === '5 Bed' ? PRIMARY_COLOR : WHITE,
                    },
                }]}
                onClick={() => {
                    setSelected('5 Bed')
                    // setData(nexusFourBedData)
                    setImage(pento5BedImage)
                }}
            />
            <CustomButton
                text={'Duplex'}
                sx={[styles.button, {
                    backgroundColor: selected === 'Duplex' ? PRIMARY_COLOR : WHITE,
                    color: selected === 'Duplex' ? '#fff' : SECONDARY_COLOR,
                    border: `1px solid ${selected === 'Duplex' ? PRIMARY_COLOR : SECONDARY_COLOR}`,
                    width: isMobile ? 'auto' : 200,
                    marginRight: 1,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: selected === 'Duplex' ? PRIMARY_COLOR : WHITE,
                    },
                }]}
                onClick={() => {
                    setSelected('Duplex')
                    // setData(nexusFourBedData)
                    // setImage(nexusFourBedImage)
                }}
            />
            <CustomButton
                text={'Penthouse'}
                sx={[styles.button, {
                    backgroundColor: selected === 'Penthouse' ? PRIMARY_COLOR : WHITE,
                    color: selected === 'Penthouse' ? '#fff' : SECONDARY_COLOR,
                    border: `1px solid ${selected === 'Penthouse' ? PRIMARY_COLOR : SECONDARY_COLOR}`,
                    width: isMobile ? 'auto' : 200,
                    marginRight: 1,
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: selected === 'Penthouse' ? PRIMARY_COLOR : WHITE,
                    },
                }]}
                onClick={() => {
                    setSelected('Penthouse')
                    // setData(nexusFourBedData)
                    // setImage(nexusFourBedImage)
                }}
            />
        </div>
    );
}
