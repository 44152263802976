import React, {useState, useEffect} from "react";
import {
    IconButton,
    Tooltip, Menu,
    MenuItem,
    Typography, ButtonGroup, Button
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomDialog from './CustomDialog';
import {PRIMARY_COLOR} from "../constants/Colors";

export default function CustomFilter({filterValue, onValueChange}) {

    const [open, setOpen] = React.useState(false)
    const [filter, setFilter] = React.useState(filterValue)

    useEffect(() => {
        setFilter(filterValue);
    }, [filterValue]);

    const handleStatus = (status) => {
        setFilter({...filter, status: status});
    }

    const handleType = (type) => {
        setFilter({...filter, type: type});
    }

    const handleDate = (date) => {
        setFilter({...filter, date: date});
    }

    const onSubmit = () => {
        onValueChange(filter);
        setOpen(false);
    }

    return (
        <>
            <Typography variant={'subtitle2'} color={'textSecondary'}>Filter</Typography>
            <Tooltip title={"Filter"}>
                <IconButton
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => setOpen(true)}
                >
                    <FilterListIcon sx={{ color: '#9E9E9E' }}/>
                </IconButton>
            </Tooltip>
            <CustomDialog title={'Filter Agent Requests'} open={open} handleClose={() => setOpen(false)}>
                <Typography variant={'subtitle2'} color={'textSecondary'}>Status</Typography>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group"
                             sx={{marginBottom: 4, display: 'flex'}}>
                    {
                        ['All', 'PENDING', 'ACCEPTED', 'CANCELLED'].map((value, index) => {
                            return (
                                <Button key={index}
                                        sx={{
                                            backgroundColor: filter.status === value ? PRIMARY_COLOR : 'transparent',
                                            color: filter.status === value ? '#fff' : PRIMARY_COLOR,
                                            '&:hover': {
                                                backgroundColor: filter.status === value ? PRIMARY_COLOR : 'transparent',
                                                color: filter.status === value ? '#fff' : PRIMARY_COLOR,
                                            },
                                        }}
                                        onClick={() => handleStatus(value)}>
                                    {value}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
                <Typography variant={'subtitle2'} color={'textSecondary'}>Date</Typography>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{marginBottom: 4}}>
                    {
                        ['Today', 'Yesterday', 'This Week', 'All Time'].map((value, index) => {
                            return (
                                <Button key={index}
                                        sx={{
                                            backgroundColor: filter.date === value ? PRIMARY_COLOR : 'transparent',
                                            color: filter.date === value ? '#fff' : PRIMARY_COLOR,
                                            '&:hover': {
                                                backgroundColor: filter.date === value ? PRIMARY_COLOR : 'transparent',
                                                color: filter.date === value ? '#fff' : PRIMARY_COLOR,
                                            },
                                        }}
                                        onClick={() => handleDate(value)}>
                                    {value}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
                <Button varient={'contained'} onClick={onSubmit}
                        style={{backgroundColor: PRIMARY_COLOR, color: '#fff'}} fullWidth>
                    Apply Filter
                </Button>
            </CustomDialog>
        </>
    );
}
