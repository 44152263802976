import React, {createContext, useEffect, useState} from 'react';
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import WorkProgressService from "../services/WorkProgress";
import GalleryService from "../services/GalleryService";

export const GalleryContext = createContext();

export default function GalleryContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [galleryList, setGalleryList] = useState([]);

    useEffect(() => {
        setOpen(true)
        GalleryService.getGallery().then(res => {
            if(res.data.success) {
                setGalleryList(res.data.data);
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch(e => {
            setOpen(false)
        })
    }, [])

    const handleAddTitle = (data, type) => {
        setOpen(true);
        GalleryService[type === 'add' ? 'addTitle' : 'updateTitle'](data).then(res => {
            if (res.data.success) {
                if (type === 'add') {
                    setGalleryList([res.data.data, ...galleryList]);
                } else {
                    let data2 = galleryList.map((value, index) => {
                        if (value.sectionId === data.sectionId) {
                            value.title = data.title
                        }
                        return value;
                    });
                    setGalleryList(data2);
                }
                setOpen(false);
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                })
            } else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    const handleAddImage = (form, setOpen, sectionId) => {
        GalleryService.addImage(form).then(res => {
            if(res.data.success) {
                //setImageArr([...imageArr, res.data.data])
                setGalleryList(galleryList.map((value, index) => {
                    if(value.sectionId === sectionId){
                        if(value.images.length === 0){
                            value.images = [res.data.data]
                        } else {
                            value.images = [...value.images, res.data.data]
                        }
                    }
                    return value
                }));
                setOpen(false);
            } else {
                setOpen(false);
            }
        }).catch(e => {
            setOpen(false);
        })
    }

    const handleDeleteTitle = (data) => {
        setOpen(true);
        GalleryService.deleteImage(data).then(res => {
            if(res.data.success){
                if(data.requestType === 'TITLE') {
                    setGalleryList(galleryList.filter((item) => item.sectionId !== data.sectionId))
                } else if (data.requestType === 'IMAGE') {
                    setGalleryList(list => {
                        return list.map((value, index) => {
                            if(value.sectionId === data.sectionId){
                                value['images'] =  value.images.filter((item2) => data.imageId !== item2.imageId);
                                return value;
                            }else{
                                return value;
                            }
                        });
                    });
                    //setImageArr(imageArr.filter((item2) => data.imageId !== item2.imageId))
                }
                setOpen(false);
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                }, 2000)
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            console.log(e)
            setOpen(false)
            alert(e)
        })
    }

    return (
        <GalleryContext.Provider value={{
            galleryList, setGalleryList, handleAddTitle, handleAddImage, handleDeleteTitle
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </GalleryContext.Provider>
    )
}