import {Helmet, HelmetProvider} from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box } from '@mui/material';


const Page = forwardRef(({ children, title = '', ...other }, ref) => (
    <HelmetProvider>
        <Box ref={ref} {...other}>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {children}
        </Box>
    </HelmetProvider>
));

export default Page;
