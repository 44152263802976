import React from 'react';
import {MenuItem, Typography} from "@mui/material";
import CustomTextField from "../../../general_components/CustomTextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "../../../general_components/LoadingButton";
import Dialog from "@mui/material/Dialog";
import {AgentContext} from "../../../contexts/AgentContext";
import {PRIMARY_COLOR} from "../../../constants/Colors";

export default function AddAgent({open, handleClose}) {

    const agentContext = React.useContext(AgentContext);
    const {authPersonName, setAuthPersonName, email, setEmail,
        password, setPassword, handleAddAgent
    } = agentContext;

    const onSubmit = (e) => {
        e.preventDefault();
        handleAddAgent()
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600'}}>
                        Add Agent
                    </Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 10}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            autoFocus={true} required
                            value={authPersonName} onValueChange={setAuthPersonName}
                            labelName={'Auth Person Name'}
                            style={{marginBottom: 15,}}
                        />
                        <CustomTextField
                            autoFocus={true} required
                            value={email} onValueChange={setEmail}
                            labelName={'Email'} type={'email'}
                            style={{marginBottom: 15,}}
                        />
                        <CustomTextField
                            autoFocus={true} required
                            value={password} onValueChange={setPassword}
                            labelName={'Password'} type={'password'}
                            style={{marginBottom: 15,}}
                        />
                        <LoadingButton type={'submit'} loading={false} text={'Add Agent'} style={{color:'#fff'}}/>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
