import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar} from '@mui/material';
import * as React from "react";
import sidebarConfig from "./SidebarConfig";
import {AuthenticationContext} from "../contexts/AuthContext";
import useResponsive from "../hooks/useResponsive";
import Scrollbar from "../general_components/Scrollbar";
import Logo from "../general_components/Logo";
import NavSection from "./NavSection";
import {GRAY_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR,} from "../constants/Colors";


const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
}));


export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const authenticationContext = React.useContext(AuthenticationContext);

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column'}
            }}
        >
            <Box
                sx={{
                    px: 4, py: 4, display: 'flex', justifyContent: 'center',
                    alignItems: 'center', flexGrow: 1, marginTop: 2.5,
                    marginBottom: 4
                }}
            >
                <Logo sx={{ marginRight: 2}}/>
            </Box>
            <NavSection navConfig={sidebarConfig}/>
        </Scrollbar>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH, bgcolor: SECONDARY_COLOR, },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: SECONDARY_COLOR,
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
