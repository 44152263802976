import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Avatar, IconButton } from '@mui/material';
import * as React from "react";
import {AuthenticationContext} from "../contexts/AuthContext";
import MenuPopover from "../general_components/MenuPopover";
import {PRIMARY_COLOR} from "../constants/Colors";
import ChangePassword from "../general_components/ChangePassword";
import LogoutIcon from '@mui/icons-material/Logout';



export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const authenticationContext = React.useContext(AuthenticationContext),
        {logOut} = authenticationContext

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar src={authenticationContext.userAccount.avatar}
                        style={{backgroundColor: PRIMARY_COLOR}}
                        alt="photoURL" />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220, backgroundColor: '#FFF', borderRadius: 5, }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" sx={{ color: PRIMARY_COLOR, fontWeight: 'bold'}} noWrap>
                        {authenticationContext.userAccount.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {authenticationContext.userAccount.email}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {authenticationContext.userAccount.role}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <ChangePassword/>
                    <Button fullWidth color="error" variant="outlined" onClick={()=> {logOut()}}
                            startIcon={<LogoutIcon/>}
                    >
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
