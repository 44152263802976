import React from 'react';
import {makeStyles} from "@mui/styles";
import useResponsive from "../../../hooks/useResponsive";
import {Typography} from "@mui/material";
import {SECONDARY_COLOR, WHITE} from "../../../constants/Colors";
import {pento4BedImage} from "./ChartData";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 15,
    },
    boxName: {
        backgroundColor: SECONDARY_COLOR,
        height: 40,
        display: 'flex', alignItems: 'center',
        justifyContent: 'center'
    },
    appointmentView: {
        width: '100%',
        minHeight: '50vh',
        backgroundColor: SECONDARY_COLOR,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const styles = {
    title: {
        textAlign: 'center',
        fontFamily: 'MontserratLight !important',
        color: WHITE,
        fontWeight: 200,
    }
}

export default function Dimension({title, image, appointmentView}) {

    const classes = useStyles();
    const isMobile = useResponsive('down', 'md');

    return (
        <div className={classes.container}>
            <div className={classes.boxName}>
                <Typography
                    variant='body1'
                    sx={[styles.title]}
                >
                    {title}
                </Typography>
            </div>
            <div style={{paddingTop: 40}}>
                {
                    !appointmentView ?
                        <img src={image}
                             alt="SKY Garden Pictures"
                             style={{width: '100%',objectFit: 'fill'}}
                             loading={"lazy"}
                        /> :
                        <div className={classes.appointmentView}>
                            <Typography variant={'body2'} style={{color: '#fff', textAlign: 'center'}}>
                                Can be viewed<br/>
                                by appointment only.
                            </Typography>
                        </div>
                }
            </div>
        </div>
    );
}
