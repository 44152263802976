import React from 'react';
import {SECONDARY_COLOR} from "../../../constants/Colors";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Typography} from "@mui/material";
import CustomTextfield from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {WorkInProgressContext} from "../../../contexts/WorkInProgressContext";

export default function AddTitle(props) {

    const {open, handleClose, titleDate, setTitleDate, openEdit, openEditId,} = props

    const workInProgressContext = React.useContext(WorkInProgressContext),
        {titleList, handleAddTitle} = workInProgressContext

    const onSubmit = (event) => {
        event.preventDefault();
        if(openEdit) {
            handleAddTitle({workProgressId: openEditId, requestType: 'TITLE', title: titleDate, order: titleList.length,}, 'editAdd');
        } else {
            handleAddTitle({requestType: 'TITLE', title: titleDate, order: titleList.length,}, 'add');
        }
        handleClose()
        setTitleDate('')
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle>
                    <Typography variant={'body1'} style={{color: SECONDARY_COLOR, fontWeight: '600'}}>
                        {openEdit ? 'Edit Title' : 'Add Title'}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 15}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextfield
                            autoFocus={true}
                            required
                            disabled={false}
                            // type={'date'}
                            value={titleDate}
                            onValueChange={setTitleDate}
                            labelName={'Title Date'}
                            style={{marginBottom: 25}}
                        />
                        <LoadingButton type={'submit'} loading={false} text={'Add Title'}
                                       sx={{color: '#fff'}}
                        />
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
