import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
} from "../constants/Colors";


const palette = {
    type: 'light',
    primary: {
        main: PRIMARY_COLOR,
    },
    secondary: {
        main: SECONDARY_COLOR,
    },
    transparent: {
        main: 'transparent'
    },
}

export default palette