import React, {useState} from 'react';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import imageCompression from "browser-image-compression";
import CustomAvatar from "../../../general_components/CustomAvatar";
import {Avatar} from "@mui/material";
import WorkProgressService from "../../../services/WorkProgress";
import Lottie from "react-lottie";
import {getLocalReplaced} from "../../../utilities/Others";
import IconButton from "@mui/material/IconButton";
import {PRIMARY_COLOR} from "../../../constants/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {WorkInProgressContext} from "../../../contexts/WorkInProgressContext";
import ConfirmationDialog from "../../../general_components/ConfirmationDialog";
import {AuthenticationContext} from "../../../contexts/AuthContext";
import {GalleryContext} from "../../../contexts/GalleryContext";
import {getAPIEndpointUrl} from "../../../utilities/ServerRequestModifier";



export default function AddImage(props) {

    const {setImageRaw, imageArr, setImageArr, item} = props
    const ref = React.useRef(null);

    const [open, setOpen] = useState(false);
    const galleryContext = React.useContext(GalleryContext),
        {handleAddImage, handleDeleteTitle} = galleryContext

    const [isConfirm, setIsConfirm] = useState(false)
    const [confirmData, setConfirmData] = useState({requestType: '', sectionId: '', imageId: ''})

    const getFile = async (e1) => {
        var file = e1.target.files[0];
        if (file !== undefined) {
            const options = {
                maxSizeMB: 1, maxWidthOrHeight: 1920,
                useWebWorker: true, fileType: 'JPEG'
            }
            try {
                setOpen(true);
                const compressedFile = await imageCompression(file, options);
                var fr = new FileReader();
                fr.onload = function (e) {
                    setImageRaw({
                        image: e.target.result,
                        file: new File([compressedFile], "image.jpeg")
                    });
                    const imageCompressed = new File([compressedFile], "image.jpeg");
                    const form = new FormData()
                    form.append('sectionId', item.sectionId)
                    form.append('image', imageCompressed)
                    form.append('requestType', 'IMAGE')
                    handleAddImage(form, setOpen, item.sectionId)
                };
                fr.readAsDataURL(compressedFile);
            } catch (error) {
                setOpen(false)
            }
        }
    }

    const handelConfirm = () => {
        handleDeleteTitle({requestType: confirmData.requestType, sectionId: confirmData.sectionId, imageId: confirmData.imageId})
        setIsConfirm(false)
    }

    const {userAccount} = React.useContext(AuthenticationContext);

    return (
        <div style={{paddingBottom: '30px'}}>
            <input
                ref={ref}
                style={{display: 'none'}}
                onChange={(e) => getFile(e)}
                type={'file'}
            />
            <div style={{position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
                {
                    imageArr.length === 0 ? null :
                        imageArr.map((item2, index) => {
                            return (
                                <div style={{position: 'relative', marginRight: 10, marginBottom: 10}}>
                                    <Avatar
                                        key={index}
                                        alt={"ImageOne"} src={getAPIEndpointUrl() + getLocalReplaced(item2)}
                                        sx={{width: 200, height: 160,
                                            minHeight: 160, borderRadius: 4,
                                            objectFit: 'cover', cursor: 'pointer',
                                        }}
                                    />
                                    {/*{*/}
                                    {/*    userAccount?.permissions?.includes('UPDATE_GALLERY') ?*/}
                                    {/*        <IconButton*/}
                                    {/*            style={{position: 'absolute', top: 2, right: 2, backgroundColor: 'rgba(0,0,0,0.87)', color: PRIMARY_COLOR, marginLeft: 6, zIndex: 1000,}}*/}
                                    {/*            variant={'contained'}*/}
                                    {/*            onClick={() => {*/}
                                    {/*                setIsConfirm(true)*/}
                                    {/*                setConfirmData({requestType: 'IMAGE', sectionId: item.sectionId, imageId: item2})*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <DeleteIcon sx={{fontSize: 16}}/>*/}
                                    {/*        </IconButton> : null*/}
                                    {/*}*/}
                                </div>
                            )
                        })
                }
                {/*{*/}
                {/*    userAccount?.permissions?.includes('UPDATE_GALLERY') ?*/}
                {/*        <CustomAvatar*/}
                {/*            avatar={''}*/}
                {/*            imageStyle={{*/}
                {/*                width: 200, height: 160, minHeight: 160, borderRadius: 4,*/}
                {/*                objectFit: 'cover', cursor: 'pointer',*/}
                {/*            }}*/}
                {/*            handleClick={() => ref.current.click()}*/}
                {/*            alphaImageStyle={{ width: 100, height: 100, bgcolor: '#FFFFFF' }}*/}
                {/*            child={*/}
                {/*                open ?*/}
                {/*                    <Lottie*/}
                {/*                        options={{*/}
                {/*                            loop: true, autoplay: true,*/}
                {/*                            animationData: require('../../../assets/Lottie/98915-loader.json'),*/}
                {/*                        }}*/}
                {/*                        height={200} width={200}*/}
                {/*                    />*/}
                {/*                    :*/}
                {/*                    <InsertPhotoIcon sx={{fontSize: 60}}/>*/}
                {/*            }*/}
                {/*        /> : null*/}
                {/*}*/}
            </div>
            <ConfirmationDialog
                open={isConfirm}
                close={setIsConfirm}
                confirm={handelConfirm}
                title={"Are you sure?"}
                message={"You may not recover this image."}
                cancelBtnText={"Cancel"}
                confirmBtnText={"Delete"}
            />
        </div>
    );
}