import React from 'react';
import {AuthenticationContext} from "../../../contexts/AuthContext";
import {Avatar, Card, IconButton, Tooltip, Typography} from "@mui/material";
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../../constants/Colors";
import {alpha} from "@mui/material/styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ConfirmationDialog from "../../../general_components/ConfirmationDialog";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        // fontWeight: 700,
        marginBottom: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '150px',
        display: 'block',
        overflow: 'hidden',
    },
    text: {
        opacity: 0.8, textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', width: '150px', display: 'block', overflow: 'hidden'
    },
    buttonContainer: {
        // display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
    },
}));

export default function AgentCard({data, index, handleDelete}) {


    const classes = useStyles();
    const [show, setShow] = React.useState(-1);

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const [isConfirm, setIsConfirm] = React.useState(false)

    function handleConfirmation(){
        handleDelete(data?.userId)
        setIsConfirm(false)
    }

    return (
        <Card
            sx={{
                py: 5,
                boxShadow: 0,
                borderRadius: 5,
                textAlign: 'center',
                height: '35vh',
                position: 'relative',
                color: "#FFF",
                backgroundColor: PRIMARY_COLOR,
                backgroundImage: (theme) =>
                    `linear-gradient(110deg, ${alpha(PRIMARY_COLOR, 0.2)} 0%, ${alpha(
                        PRIMARY_COLOR,
                        0.7
                    )} 100%)`,
            }}
            // className={classes.item}
            onMouseOver={() => setShow(index)}
            onMouseLeave={() => setShow(-1)}
        >
            <div className={classes.buttonContainer}>
                {
                    !(show === index)  ? null :
                        !(userAccount?.permissions?.includes('DELETE_AGENT')) ? null :
                        <Tooltip title="Delete">
                            <IconButton onClick={() => {setIsConfirm(true)}}>
                                <DeleteRoundedIcon sx={{ color: '#fff',}}/>
                            </IconButton>
                        </Tooltip>
                }
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar
                    sx={{
                        width: 60,
                        height: 60,
                        marginBottom: 2,
                        opacity: 0.8,
                        color: '#FFF',
                        backgroundColor: PRIMARY_COLOR,
                        backgroundImage: (theme) =>
                            `linear-gradient(100deg, ${alpha(SECONDARY_COLOR, 0.5)} 0%, ${alpha(
                                SECONDARY_COLOR,
                                1
                            )} 100%)`,
                    }}
                    src={data?.avatar}/>
                <Typography variant={'body1'} sx={{fontWeight: 700, opacity: 0.8,}} className={classes.title}>
                    {data?.name}
                </Typography>
                <Typography variant={'body1'} className={classes.text}>{data?.email}</Typography>
                <Typography variant={'body2'} className={classes.text}>{data?.role}</Typography>
            </div>
            <ConfirmationDialog
                open={isConfirm}
                close={setIsConfirm}
                confirm={handleConfirmation}
                title={"Are you sure?"}
                message={"You may not recover the email"}
                cancelBtnText={"Cancel"}
                confirmBtnText={"Delete"}
            />
        </Card>
    );

}
