import React, {createContext, useState} from 'react';
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import AgentService from "../services/AgentService";
import AdminService from "../services/AdminService";


export const AgentContext = createContext();

export default function AgentContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [showDetails, setShowDetails] = useState(false),
        [item, setItem] = useState(null);

    const [agentRequestData, setAgentRequestData] = useState([]);
    //Confirmation
    const [openDelete, setOpenDelete] = useState(false),
        [requestId, setRequestId] = useState('');

    const getAgentRequestsData = () => {
        setOpen(true);
        AgentService.getAgentRequests().then(res => {
            setOpen(false);
            if(res.data.success){
                setAgentRequestData(arr => [...res.data.data]);
                console.log(res.data.data)
            }else {
                alert(res.data.error)
            }
        }).catch(e => {
            // alert(e)
            setOpen(false);
        }).finally(() => {
            setOpen(false);
        })
    }

    const updateRequest = (id, update) => {
        setOpen(true)
        if(agentRequestData.length !== 0) {
            setAgentRequestData(
                agentRequestData.map(item => {
                    if(item.requestId === id){
                        setOpen(false)
                        // setConfirm(true);
                        // setTimeout(() => {
                        //     setConfirm(false);
                        // }, 2000);
                        setItem({...item, isRead: true, ...update})
                        return {...item, isRead: true, ...update}
                    }else {
                        setOpen(false)
                        return item;
                    }
                }));
            setOpen(false)
        }
        setOpen(false)
    }
    
    const handleDelete = () => {
        setOpen(true)
        AgentService.deleteAgentRequest(requestId).then(res => {
            if(res.data.success){
                setAgentRequestData(agentRequestData.filter((item) => item.requestId !== requestId))
                setOpenDelete(false);
                setRequestId('')
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                    setItem(null)
                    setShowDetails(false)
                })
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    //Approved Agents
    const [agents, setAgents] = useState([]);

    const [authPersonName, setAuthPersonName] = useState(''),
        [email, setEmail] = useState(''),
        [password, setPassword] = useState('');

    const [openAddAgent, setOpenAddAgent] = useState(false);


    const getAgents = () => {
        setOpen(true)
        AgentService.getAgents().then(res => {
            console.log(res)
            if(res.data.success){
                setAgents(res.data.data);
                setOpen(false)
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }

    const handleAddAgent = () => {
        const data = {name: authPersonName, email: email, password: password};
        setOpen(true)
        AgentService.addAgent(data).then(res => {
            if(res.data.success){
                setAgents([...agents, res.data.data]);
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                    setOpenAddAgent(false)
                })
                //    Clearing current fields
                setAuthPersonName('');
                setEmail('');
                setPassword('')
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }


    const handleDeleteApprovedAgent = (id) => {
        setOpen(true)
        AgentService.deleteAgent(id).then(res => {
            if(res.data.success){
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                })
                //    Clearing current fields
                setAgents(agents.filter((items) => items?.userId !== id ))
            } else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false)
            alert(e)
        })
    }


    return (
        <AgentContext.Provider value={{
            showDetails, setShowDetails,item, setItem, agentRequestData,
            openDelete, setOpenDelete, requestId, setRequestId,
            getAgentRequestsData, updateRequest, handleDelete,
            agents, setAgents, openAddAgent, setOpenAddAgent,
            authPersonName, setAuthPersonName, email, setEmail, password, setPassword,
            handleDeleteApprovedAgent, handleAddAgent, getAgents,
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </AgentContext.Provider>
    )
}