import SVGOfficePlan from '../../../assets/Images/Office-1-4.png';

export const

    pento4BedImage = require('../../../assets/Images/Pentos-4-Bed.png'),
    pento5BedImage = require('../../../assets/Images/Pentos-5-Bed.png'),
    nexusThreeBedImage = require('../../../assets/Images/3-Bedroom.png'),
    nexusFourBedImage = require('../../../assets/Images/4-Bedroom.png'),
    E5A5B4A4B = require('../../../assets/Images/E-5A-5B-4A-4B.png'),
    skyExchangeImage = SVGOfficePlan,

    //Pento Data
    pentoData = [
        {
            floorName: 'Upper Roof (PH)',
            unit: '', room: '',
            unitStatus: 'sold',
            netArea: '', grossArea: '34,030',
            price: 'xxxxx',
        },
        {
            floorName: 'Lower Roof (PH)',
            unit: 'B-34 (PH)', room: '',
            unitStatus: 'sold',
            netArea: '', grossArea: '34,030',
            price: 'xxxxx'
        },
        {
            floorName: '34th (PH)',
            unit: 'B-34 (PH)', room: '',
            unitStatus: 'sold',
            netArea: '', grossArea: '34,030',
            price: 'xxxxx'
        },
        {
            floorName: '33rd (PH)',
            unit: 'B-33 (PH)', room: '',
            unitStatus: 'sold',
            netArea: '', grossArea: '34,030',
            price: 'xxxxx'
        },
        {
            floorName: '32nd',
            unit: 'B-32 (Duplex)', room: '6',
            unitStatus: 'reserved',
            netArea: '', grossArea: '18,100',
            price: 'xxxxx'
        },
        {
            floorName: '31st',
            unit: 'B-31 (Duplex)', room: '6',
            unitStatus: 'reserved',
            netArea: '', grossArea: '18,100',
            price: 'xxxxx'
        },
        {
            floorName: '30th',
            unit: 'B-30', room: '4',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '29th',
            unit: 'B-29', room: '4',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '28th',
            unit: 'B-28', room: '4',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '27th',
            unit: 'B-27', room: '5',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '26th',
            unit: 'B-26', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '25th',
            unit: 'B-25', room: '5',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '24th',
            unit: 'B-24', room: '4',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '23th',
            unit: 'B-23', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '22nd',
            unit: 'B-22', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '21st',
            unit: 'B-21', room: '5',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '20th',
            unit: 'B-20', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '19th',
            unit: 'B-19', room: '5',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '18th',
            unit: 'B-18', room: '4',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '17th',
            unit: 'B-17', room: '5',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '16th',
            unit: 'B-16', room: '5',
            unitStatus: 'sold',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '15th',
            unit: 'B-15', room: '5',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '14th',
            unit: 'B-14', room: '4',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '13th',
            unit: 'B-13', room: '5',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '12th',
            unit: 'B-12', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '11th',
            unit: 'B-11', room: '5',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '10th',
            unit: 'B-10', room: '4',
            unitStatus: 'available',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
        {
            floorName: '9th',
            unit: 'B-9', room: '5',
            unitStatus: 'reserved',
            netArea: '7,170', grossArea: '8,840',
            price: 'xxxxx'
        },
    ],

    //Nexus Bedrooms Data
    nexusThreeBedData = [
        {
            floorName: '32nd',
            unit: 'A-3201',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '31st',
            unit: 'A-3101',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '30th',
            unit: 'A-3001',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '29th',
            unit: 'A-2901',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '28th',
            unit: 'A-2801',
            unitStatus: 'reserved',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '27th',
            unit: 'A-2701',
            unitStatus: 'reserved',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '26th',
            unit: 'A-2601',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '25th',
            unit: 'A-2501',
            unitStatus: 'reserved',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '24th',
            unit: 'A-2401',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '23rd',
            unit: 'A-2301',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '22nd',
            unit: 'A-2201',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '21st',
            unit: 'A-2101',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '20th',
            unit: 'A-2001',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '19th',
            unit: 'A-1901',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '18th',
            unit: 'A-1801',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '17th',
            unit: 'A-1701',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '16th',
            unit: 'Service',
            unitStatus: 'available',
            netArea: '', grossArea: '',
            price: 'xxxxx'
        },
        {
            floorName: '15th',
            unit: 'A-1501',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '14th',
            unit: 'A-1401',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '13th',
            unit: 'A-1301',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '12th',
            unit: 'A-1201',
            unitStatus: 'sold',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '11th',
            unit: 'A-1101',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '10th',
            unit: 'A-1001',
            unitStatus: 'reserved',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
        {
            floorName: '9th',
            unit: 'A-901',
            unitStatus: 'available',
            netArea: '3,150', grossArea: '3,150',
            price: 'xxxxx'
        },
    ],

    nexusFourBedData = [
        {
            floorName: '32nd',
            unit: 'A-3202',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '31st',
            unit: 'A-3102',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '30th',
            unit: 'A-3002',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '29th',
            unit: 'A-2902',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '28th',
            unit: 'A-2802',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '27th',
            unit: 'A-2702',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '26th',
            unit: 'A-2602',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '25th',
            unit: 'A-2502',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '24th',
            unit: 'A-2402',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '23rd',
            unit: 'A-2302',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '22nd',
            unit: 'A-2202',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '21st',
            unit: 'A-2102',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '20th',
            unit: 'A-2002',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '19th',
            unit: 'A-1902',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '18th',
            unit: 'A-1802',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '17th',
            unit: 'A-1702',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '16th',
            unit: 'A-1602',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '15th',
            unit: 'A-1502',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '14th',
            unit: 'A-1402',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '13th',
            unit: 'A-1302',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '12th',
            unit: 'A-1202',
            unitStatus: 'reserved',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '11th',
            unit: 'A-1102',
            unitStatus: 'available',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '10th',
            unit: 'A-1002',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
        {
            floorName: '9th',
            unit: 'A-902',
            unitStatus: 'sold',
            netArea: '3,330', grossArea: '4,130',
            price: 'xxxxx'
        },
    ],

    // Sky Exchange Data
    skyExchangeData = [
        {
            floorName: '15th',
            unit: 'O-1501',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '14th',
            unit: 'O-1401',
            unitStatus: 'sold',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '13th',
            unit: 'O-1301',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '12th',
            unit: 'O-1201',
            unitStatus: 'sold',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '11th',
            unit: 'O-1101',
            unitStatus: 'reserved',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '10th',
            unit: 'O-1001',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '9th',
            unit: 'O-901',
            unitStatus: 'sold',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '8th',
            unit: 'O-801',
            unitStatus: 'reserved',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '7th',
            unit: 'O-701',
            unitStatus: 'sold',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '6th',
            unit: 'O-601',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '5th',
            unit: 'O-501',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '4th',
            unit: 'O-401',
            unitStatus: 'available',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '3rd',
            unit: 'O-301',
            unitStatus: 'sold',
            netArea: '8,900', grossArea: '11,160',
            price: 'xxxxx'
        },
        {
            floorName: '2nd',
            unit: 'O-201',
            unitStatus: 'available',
            netArea: '11,591', grossArea: '14,155',
            price: 'xxxxx'
        },
    ]