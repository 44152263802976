import React from 'react';
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {fDate2, fTime} from "../../../utilities/formatTime";
import Chip from "@mui/material/Chip";
import {PRIMARY_COLOR} from "../../../constants/Colors";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingTop: '0.5%'
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            textDecoration: "none",
        },
    }
}));

export default function BrochureRequestDetails({item}) {

    const classes = useStyles();

    const GridItemData = ({title, value}) => {
        return (
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    {title}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {value}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0} mb={0} paddingLeft={1} className={classes.root} id="Details">
            <GridItemData title={"Name"} value={item.name ? item.name : 'N/A'}/>
            <GridItemData title={"Email"} value={item.email}/>
            <GridItemData title={"Phone"} value={item.phoneNumber}/>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    Date - Time
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {fDate2(item.createdAt) + ' - ' + fTime(item.createdAt)}
                </Typography>
            </Grid>
            {
                item.property.length > 0 ?
                    <Grid item xs={12} sm={12} md={12} pb={1.5}>
                        <Typography
                            variant={'subtitle2'} color={'textSecondary'}
                            sx={{fontFamily: 'MontserratRegular !important', marginBottom: 1}}
                        >
                            Property
                        </Typography>
                        {
                            item.property?.map((value, index) => {
                                return (
                                    <Chip key={index} label={value}
                                          style={{margin: '0px 10px 10px 0px', backgroundColor: PRIMARY_COLOR, color: '#fff'}}/>
                                )
                            })
                        }
                    </Grid> : null
            }
        </Grid>
    );
}

