import React from 'react';
import Page from "../../general_components/Page";
import StackCustom from "../../general_components/StackCustom";
import {PRIMARY_COLOR} from '../../constants/Colors';
import AddTitle from "./components/AddTitle";
import {WorkInProgressContext} from "../../contexts/WorkInProgressContext";
import WorkImages from "./components/WorkImages";
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {Typography} from "@mui/material";
import {AuthenticationContext} from "../../contexts/AuthContext";

export default function WorkInProgress() {

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openEditId, setOpenEditId] = React.useState(null);

    const workInProgressContext = React.useContext(WorkInProgressContext),
        {titleList} = workInProgressContext

    const [titleDate, setTitleDate] = React.useState('');

    const {userAccount} = React.useContext(AuthenticationContext);

    return (
        <Page title="Riviera SkyGarden | Work In Progress" style={{paddingBottom: '6vh'}}>
            <StackCustom
                text="Work In Progress"
                isText={true}
                child={
                    userAccount?.permissions?.includes('UPDATE_WORK_IN_PROGRESS') ?
                    <IconButton
                        style={{backgroundColor: PRIMARY_COLOR, color: '#fff', marginLeft: 6}}
                        variant={'contained'}
                        onClick={() => setOpen(true)}
                    >
                        <AddRoundedIcon size={18}/>
                    </IconButton> : null
                }
            />
            <Typography variant={'body2'}><i>*Image upload size should be upto 1MB.</i></Typography>
            <br/>
            {
                titleList.length === 0 ? null :
                    titleList.map((item, index) => {
                        return (
                            <WorkImages
                                key={index} item={item} index={index}
                                setOpen={setOpen} setOpenEdit={setOpenEdit}
                                setTitleDate={setTitleDate}
                                setOpenEditId={setOpenEditId}
                            />
                        )
                    })
            }
            <AddTitle
                open={open}
                handleClose={() => {
                    setOpen(false)
                    setOpenEdit(false)
                }}
                titleDate={titleDate} setTitleDate={setTitleDate}
                openEdit={openEdit}
                openEditId={openEditId}
            />
        </Page>
    );
}

