import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class InterestService {

    getInterests() {
        return http.get(addTokenToURL("/admin/interests"));
    }

    updateInterest(data){
        return http.put("/admin/interest", addTokenToData(data));
    }

    deleteInterest(id){
        return http.delete(addTokenToURL("/admin/interest")+`&interestId=${id}`);
    }
}

export default new InterestService();
