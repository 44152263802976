import React from 'react';
import {Box, IconButton, Stack} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopover from "./AccountPopover";
import {SECONDARY_COLOR} from "../constants/Colors";


export default function AccountBar({ onOpenSidebar, sx }) {
    return (
        <div style={{zIndex: 10, display: 'flex', flexDirection: 'row', alignItems: "center", maxHeight: 60, minHeight: 60, padding: '5px 4px'}}>
            <IconButton
                onClick={onOpenSidebar}
                sx={{ ml: 2, color: '#fff', backgroundColor: SECONDARY_COLOR, maxHeight: 40, minHeight: 40, display: { md: 'none' } }}
            >
                <MenuIcon/>
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
            </Box>
            <Stack
                direction="row" alignItems="center"
                style={{position: 'fixed', right: 20, top: 10}}
                spacing={{ xs: 0.5, sm: 1.5 }}>
                <AccountPopover />
            </Stack>
        </div>
    );
}