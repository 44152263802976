import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class AgentService {

    getAgentRequests() {
        return http.get(addTokenToURL("/admin/agent-requests"));
    }

    updateAgentRequests(data){
        return http.put("/admin/agent-request", addTokenToData(data));
    }

    deleteAgentRequest(id){
        return http.delete(addTokenToURL("/admin/agent-request")+`&requestId=${id}`);
    }


    getAgents() {
        return http.get(addTokenToURL("/admin/agents"));
    }

    addAgent(data) {
        return http.post("/admin/agent", addTokenToData(data));
    }

    deleteAgent(id){
        return http.delete(addTokenToURL("/admin/agent")+`&userId=${id}`);
    }

}

export default new AgentService();
