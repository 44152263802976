import Icon from "../assets/Logos/SkyGarden_Logo.svg"


export default function Logo({ sx }) {
  return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <img src={Icon} width={180}/>
      </div>
  );
}
