import React, {useState} from 'react';
import AccountBar from "./AccountBar";
import DashboardSidebar from "./DashboardSidebar";

export default function BarContainer(props) {

    const [open, setOpen] = useState(false);

    return (
        <>
            <AccountBar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        </>
    );
}