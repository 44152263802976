import Home from "../pages/home/Home";
import AgentsRequests from "../pages/agents_requests/AgentsRequests";
import Agents from "../pages/agents/Agents";
import Interest from "../pages/interest/Interest";
import AdminsContextProvider from "../contexts/AdminsContext";
import Admins from "../pages/admins/Admins";
import ChartContextProvider from "../contexts/ChartContext";
import WorkInProgress from "../pages/work_in_progress/WorkInProgress";
import WorkInProgressContextProvider from "../contexts/WorkInProgressContext";
import BrochureRequests from "../pages/brochure_requests/BrochureRequests";
import GalleryContextProvider from "../contexts/GalleryContext";
import Gallery from "../pages/gallery/Gallery";


export const AuthRoutes = [
    {
        path: '/home', component:<ChartContextProvider> <Home/> </ChartContextProvider>
    },
    {
        path: '/agents-requests', component: <AgentsRequests/>
    },
    {
        path: '/interest', component: <Interest/>
    },
    {
        path: '/agents', component: <Agents/>
    },
    {
        path: '/brochure-requests', component:  <BrochureRequests/>
    },
    {
        path: '/gallery', component: <GalleryContextProvider><Gallery/></GalleryContextProvider>
    },
    {
        path: '/work-in-progress', component: <WorkInProgressContextProvider><WorkInProgress/></WorkInProgressContextProvider>
    },
    {
        path: '/admins', component:  <AdminsContextProvider> <Admins/> </AdminsContextProvider>
    },
]