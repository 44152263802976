import React from 'react';
import {makeStyles} from "@mui/styles";
import {Grid, Typography} from "@mui/material";
import {WHITE, SECONDARY_COLOR} from "../../../constants/Colors";
import filePath from "../../../assets/Ali Zaid_CV.pdf"
import {getLocalReplaced} from "../../../utilities/Others";
import {getAPIEndpointUrl} from "../../../utilities/ServerRequestModifier";
import {AuthenticationContext} from "../../../contexts/AuthContext";
import {fDate2, fTime} from "../../../utilities/formatTime";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // border: '1px solid #eee',
        // borderRadius: 20,
        // borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
        overflow: 'hidden',
        overflowY: 'auto',
        // padding: '8% 4%',
        paddingTop: '2%'
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            textDecoration: "none",
        },
    }
}));
export default function AgentsRequestsDetails({item, setItem}) {

    const classes = useStyles();

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const GridItemData = ({title, value}) => {
        return (
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    {title}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {value}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0} mb={0} paddingTop={2} paddingLeft={1} className={classes.root} id="Details">
            <GridItemData title={"NAME OF THE COMPANY"} value={item.companyName}/>
            <GridItemData title={"YEAR OF ESTABLISHMENT"} value={item.estYear}/>
            <GridItemData title={"TYPE OF ORGANIZATION"} value={item.orgType}/>
            <GridItemData title={"BRANCHES"} value={item.branches}/>
            <GridItemData title={"ADDRESS"} value={item.address}/>
            <GridItemData title={"CONTACT NUMBER"} value={item.contactNumber}/>
            <GridItemData title={"LANDLINE NO."} value={item.landlineNumber}/>
            <GridItemData title={"AUTHORISED PERSON NAME"} value={item.authPersonName}/>
            <GridItemData title={"DESIGNATION"} value={item.designation}/>
            <GridItemData title={"EMAIL ADDRESS"} value={item.email}/>
            <GridItemData title={"WEBSITE"} value={item.website}/>
            <GridItemData title={"NUMBER OF AGENTS"} value={item.agentsNumber}/>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                    pb={1}
                >
                    VALID LICENSE COPY
                </Typography>
                <Typography
                    variant={'body2'}
                    sx={{
                        userSelect: 'none', cursor: 'pointer',
                        fontFamily: 'MontserratRegular !important',
                        backgroundColor: SECONDARY_COLOR, color: WHITE,
                        display: 'inline', padding: 1,
                        borderRadius: 30
                    }}
                    onClick={() => {
                        const file = getAPIEndpointUrl() + item.license;
                        window.open(file);
                    }}
                >
                    VIEW & DOWNLOAD
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                    pb={1}
                >
                    TAX CERTIFICATE
                </Typography>
                <Typography
                    variant={'body2'}
                    sx={{
                        userSelect: 'none', cursor: 'pointer',
                        fontFamily: 'MontserratRegular !important',
                        backgroundColor: SECONDARY_COLOR, color: WHITE,
                        display: 'inline', padding: 1,
                        borderRadius: 30
                    }}
                    onClick={() => {
                        const file = getAPIEndpointUrl() + item.taxCertification;
                        window.open(file);
                    }}
                >
                    VIEW & DOWNLOAD
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    Date - Time
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {fDate2(item.createdAt) + ' - ' + fTime(item.createdAt)}
                </Typography>
            </Grid>
        </Grid>
    );
}

