import React from 'react';
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {fDate2, fTime} from "../../../utilities/formatTime";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingTop: '0.5%'
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            textDecoration: "none",
        },
    }
}));

export default function InterestDetails({item}) {

    const classes = useStyles();

    const GridItemData = ({title, value}) => {
        return (
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    {title}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {value}
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0} mb={0} paddingLeft={1} className={classes.root} id="Details">
            <GridItemData title={"First Name"} value={item.firstName}/>
            <GridItemData title={"Last Name"} value={item.lastName}/>
            <GridItemData title={"Email"} value={item.email}/>
            <GridItemData title={"Contact Number"} value={item.contactNumber}/>
            <GridItemData title={"Property Type"} value={item.propertyType}/>
            <GridItemData title={"Number of Bedrooms"} value={item.propertySubtype}/>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    Enquiring as
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {
                        item.isLocal ? 'Local' : 'International'
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} pb={1.5}>
                <Typography
                    variant={'subtitle2'} color={'textSecondary'}
                    sx={{fontFamily: 'MontserratRegular !important',}}
                >
                    Date - Time
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ fontFamily: 'MontserratRegular !important',}}
                >
                    {fDate2(item.createdAt) + ' - ' + fTime(item.createdAt)}
                </Typography>
            </Grid>
        </Grid>
    );
}

