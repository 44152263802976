import {CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

export default function LoadingButton(props)
{
    const {loading, type, text, className, onClick} = props
    return (
        <Button variant={'contained'} fullWidth type={type} disable={loading} className={className}
                onClick={onClick} {...props}>
            {
                loading ? <CircularProgress style={{width: 25, height: 25}}/> : text
            }
        </Button>
    )
}