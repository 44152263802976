import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Typography} from "@mui/material";
import {PRIMARY_COLOR, WHITE} from "../../../constants/Colors";
import DialogContent from "@mui/material/DialogContent";
import CustomTextField from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {ChartContext} from "../../../contexts/ChartContext";

export default function AddPrice(props) {

    const {open, handleClose, value, selected, nexusSelected, currentPrice, setCurrentPrice} = props;
    const chartContext = React.useContext(ChartContext);
    const { handleUpdateFloorPlan } = chartContext



    const onSubmit = async (e) => {
        e.preventDefault();
        handleUpdateFloorPlan(selected, nexusSelected, value, {price: currentPrice});
        handleClose();
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600', flexGrow: 1}}>
                        Edit Price
                    </Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 0}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            autoFocus={true}
                            required
                            disabled={false}
                            value={currentPrice}
                            onValueChange={setCurrentPrice}
                            labelName={'Price'}
                            style={{marginBottom: 25}}
                        />
                        <LoadingButton
                            type={'submit'} loading={false} text={'Update'}
                            sx={{color: WHITE}}
                            />
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

