import * as React from 'react';
import {makeStyles} from "@mui/styles";
import {Modal} from "@mui/material";
import Lottie from "react-lottie";

const useStyles = makeStyles({
    container: {
        // animationName: '$spin',
        // animationDuration: '4s',
        // animationIterationCount: 'infinite',
        // animationTimingFunction: 'linear',
    },
    "@keyframes spin": {
        "from": {
            transform: "rotate(0deg)"
        },
        "to": {
            transform: "rotate(360deg)"
        }
    },
});

export default function Loader({open, close}) {

    const classes = useStyles();

    const handelClose = () => {
        close(false);
    };

    return (
        <Modal
            open={open}
            onClose={() => handelClose()}
            disableEnforceFocus
        >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <div className={classes.container}>
                    <Lottie
                        options={{
                            loop: true, autoplay: true,
                            animationData: require('../assets/Lottie/98915-loader.json'),
                        }}
                        height={200} width={200}
                    />
                </div>
            </div>
        </Modal>
    );
}
