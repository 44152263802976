import React, {createContext, useEffect, useState} from 'react';
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import WorkProgressService from "../services/WorkProgress";

export const WorkInProgressContext = createContext();

export default function WorkInProgressContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [titleList, setTitleList] = useState([]);

    useEffect(() => {
        setOpen(true)
        WorkProgressService.getWorkProgress().then(res => {
            if(res.data.success) {
                console.log(res.data)
                setTitleList(res.data.data);
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch(e => {
            setOpen(false)
        })
    }, [])

    const handleAddTitle = (data, type) => {
        setOpen(true);
        WorkProgressService[type === 'add' ? 'addTitle' : 'updateTitle'](data).then(res => {
            if(res.data.success){
                console.log(res)
                if(type === 'add') {
                    setTitleList([res.data.data, ...titleList]);
                } else {
                    let data2 = titleList.map((value, index) => {
                        console.log(value)
                        if(value.workProgressId === data.workProgressId) {
                            console.log(res.data.data)
                            value.title = data.title
                        }
                        return value;
                    });
                    setTitleList(data2);
                }
                setOpen(false);
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                })
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            console.log(e)
            setOpen(false)
            alert(e)
        })
    }

    const handleDeleteTitle = (data) => {
        setOpen(true);
        WorkProgressService.deleteTitle(data).then(res => {
            if(res.data.success){
                if(data.requestType === 'TITLE') {
                    setTitleList(titleList.filter((item) => item.workProgressId !== data.workProgressId))
                } else if (data.requestType === 'IMAGE') {
                    setTitleList(list => {
                        return list.map((value, index) => {
                            if(value.workProgressId === data.workProgressId){
                                value['images'] =  value.images.filter((item2) => data.imageId !== item2.imageId);
                                return value;
                            }else{
                                return value;
                            }
                        });
                    });
                    //setImageArr(imageArr.filter((item2) => data.imageId !== item2.imageId))
                }
                setOpen(false);
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                }, 2000)
            }else {
                setOpen(false)
                alert(res.data.error)
            }
        }).catch(e => {
            console.log(e)
            setOpen(false)
            alert(e)
        })
    }

    const handleAddImage = (form, setOpen, workProgressId) => {
        WorkProgressService.addImage(form).then(res => {
            if(res.data.success) {
                //setImageArr([...imageArr, res.data.data])
                setTitleList(titleList.map((value, index) => {
                    if(value.workProgressId === workProgressId){
                        if(value.images.length === 0){
                            value.images = [res.data.data]
                        } else {
                            value.images = [...value.images, res.data.data]
                        }
                    }
                    return value
                }));
                setOpen(false);
            } else {
                setOpen(false);
            }
        }).catch(e => {
            setOpen(false);
        })
    }

    return (
        <WorkInProgressContext.Provider value={{
            titleList, setTitleList,
            handleAddTitle, handleDeleteTitle, handleAddImage,
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </WorkInProgressContext.Provider>
    )
}