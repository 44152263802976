import moment from 'moment';


export function fDate(date) {
  return moment(date).format('dddd DD MMMM yyyy');
}

export function fDate2(date) {
  return moment(date).format('DD MMMM yyyy');
}

export function fDateSecond(date) {
  return moment(date).format('DD MMM');
}

export function fTime(date) {
  return moment(date).format('hh:mm a');
}