import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Typography} from "@mui/material";
import {PRIMARY_COLOR} from "../constants/Colors";

export default function CustomDialog({title, open, handleClose, children}) {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='sm'>
            <DialogTitle>
                <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600'}}>{title}</Typography>
            </DialogTitle>
            <DialogContent style={{width: 450, paddingTop: 15}}>
                {children}
            </DialogContent>
        </Dialog>
    );
}
