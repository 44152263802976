import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {PRIMARY_COLOR,} from "../../../constants/Colors";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import CustomTextField from "../../../general_components/CustomTextField";
import {AdminsContext} from "../../../contexts/AdminsContext"
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import LoadingButton from "../../../general_components/LoadingButton";
import Rights from "./Rights";
import AdminService from "../../../services/AdminService";

export default function EditAdmin({open, setOpen, data}) {

    const [name, setName] = useState('');
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);

    const {updateAdmin} = React.useContext(AdminsContext);

    useEffect(() => {
        if(data){
            setName(data.name);
            setRole(data.role);
            setPermissions(data.permissions);
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        AdminService.editAdmin({userId: data.userId, name, role, permissions}).then(response => {
            if(response.data.success){
                updateAdmin(data.userId, {name, role, permissions});
                setOpen(false);
            }else{
                alert('Something went wrong!');
            }
        }).catch(error => {
            alert('Something went wrong!');
        }).finally(() => {setOpen(false);setLoading(false)});
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600'}}>Edit Admin</Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 10}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            autoFocus={true} value={name} onValueChange={setName} required
                            labelName={'Name'}
                            style={{marginBottom: 15,}}
                        />
                        <CustomTextField
                            labelName={'Email'} value={data.email} onValueChange={()=>{}} type={'email'} required
                            style={{marginBottom: 15,}} disabled={true}
                        />
                        <CustomTextField labelName={'Role'} required
                                         style={{marginBottom: 15,}}
                                         select value={role} onValueChange={(value) => {
                            setRole(value);
                            if(value === 'SUPER_ADMIN'){
                                setPermissions(
                                    [
                                        'ADD_ADMIN', 'DELETE_ADMIN', 'EDIT_FLOOR_STATUS',
                                        'EDIT_FLOOR_PRICE', 'ADD_AGENT', 'DELETE_AGENT',
                                        'UPDATE_AGENT_REQUESTS', 'DELETE_INTEREST'
                                    ]
                                )
                            }else{setPermissions([])}
                        }}
                        >
                            {
                                [{title: 'Super Admin', value: 'SUPER_ADMIN'}, {title: 'Admin', value: 'ADMIN'}].map((value, index) => {
                                    return <MenuItem key={index} value={value.value}>{value.title}</MenuItem>
                                })
                            }
                        </CustomTextField>
                        {
                            role === 'SUPER_ADMIN' || role === null ? null :
                                <div style={{marginBottom: 15}}>
                                    <Rights
                                        type={"Admin"}
                                        data={[
                                            {name: 'Add Admin', value: 'ADD_ADMIN'},
                                            {name: 'Delete Admin', value: 'DELETE_ADMIN'},
                                            {name: 'Update Admin', value: 'UPDATE_ADMIN'},
                                            {name: 'Edit Floor Status', value: 'EDIT_FLOOR_STATUS'},
                                            {name: 'Edit Floor Price', value: 'EDIT_FLOOR_PRICE'},
                                            {name: 'Add Agent', value: 'ADD_AGENT'},
                                            {name: 'Delete Agent', value: 'DELETE_AGENT'},
                                            {name: 'Update Agent', value: 'UPDATE_AGENT_REQUESTS'},
                                            {name: 'Delete Interests', value: 'DELETE_INTEREST'},
                                            {name: 'Update Work In Progress', value: 'UPDATE_WORK_IN_PROGRESS'},
                                        ]}
                                        value={permissions}
                                        setValue={setPermissions}
                                    />
                                </div>
                        }
                        <LoadingButton type={'submit'} loading={loading} text={'Edit Admin'}  style={{color:'#fff', marginTop: 15}}/>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
