import React, {useEffect} from 'react';
import Page from "../../general_components/Page";
import {PRIMARY_COLOR} from "../../constants/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import IconButton from "@mui/material/IconButton";
import StackCustom from "../../general_components/StackCustom";
import {Grid} from "@mui/material";
import {AgentContext} from "../../contexts/AgentContext";
import AddAgent from "./components/AddAgent";
import AgentCard from "./components/AgentCard";
import {AuthenticationContext} from "../../contexts/AuthContext";

export default function Agents() {

    const agentContext = React.useContext(AgentContext);
    const { agents, getAgents, handleDeleteApprovedAgent, openAddAgent, setOpenAddAgent } = agentContext;

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    useEffect(() => {
        getAgents()
    }, [])

    return (
        <Page title="Riviera SkyGarden | Agents" style={{paddingBottom: '6vh'}}>
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: '100%', minWidth: '100%',}}>
                <StackCustom text="Agents"
                             child={
                                 !(userAccount?.permissions?.includes('ADD_AGENT')) ? null :
                                     <IconButton style={{backgroundColor: PRIMARY_COLOR, color: '#fff', marginLeft: 6}}
                                                 variant={'contained'}
                                                 onClick={() => setOpenAddAgent(true)}
                                     >
                                         <AddRoundedIcon size={18}/>
                                     </IconButton>
                             }
                />
                <Grid container spacing={5}>
                    {
                        agents.map((item, index) => (
                            <Grid item xs={12} sm={6} md={3}>
                                <AgentCard data={item} index={index}
                                           handleDelete={handleDeleteApprovedAgent}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
            <AddAgent open={openAddAgent} handleClose={() => setOpenAddAgent(false)}/>
        </Page>
    );
}
