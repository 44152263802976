import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class BrochureService {

    getBrochureRequests() {
        return http.get(addTokenToURL("/admin/brochure-requests"));
    }

    updateBrochureRequest(data){
        return http.put("/admin/brochure-request", addTokenToData(data));
    }

}

export default new BrochureService();
