import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import {Avatar, Card, IconButton, Tooltip, Typography} from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import {alpha} from "@mui/material/styles";
import ConfirmationDialog from "../../../general_components/ConfirmationDialog";
import {AuthenticationContext} from "../../../contexts/AuthContext"
import {PRIMARY_COLOR, SECONDARY_COLOR,} from "../../../constants/Colors"
import EditAdmin from "./EditAdmin";

const useStyles = makeStyles((theme) => ({
    title: {
        // fontWeight: 700,
        marginBottom: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '150px',
        display: 'block',
        overflow: 'hidden',
    },
    text: {
        opacity: 0.8, textOverflow: 'ellipsis',
        whiteSpace: 'nowrap', width: '150px', display: 'block', overflow: 'hidden'
    },
    buttonContainer: {
        // display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
    },
}));


export default function AdminCard({data, index, handleDelete}) {
    const classes = useStyles();
    const [show, setShow] = React.useState(-1);

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const [isConfirm, setIsConfirm] = React.useState(false)
    const [openEdit, setOpenEdit] = React.useState(false)

    function handleConfirmation(){
        handleDelete(data.userId)
        setIsConfirm(false)
    }

    return (
        <Card
            sx={{
                py: 5,
                boxShadow: 0,
                borderRadius: 5,
                textAlign: 'center',
                height: '40vh',
                position: 'relative',
                color: "#FFF",
                backgroundColor: PRIMARY_COLOR,
                backgroundImage: (theme) =>
                    `linear-gradient(110deg, ${alpha(PRIMARY_COLOR, 0.2)} 0%, ${alpha(
                        PRIMARY_COLOR,
                        0.7
                    )} 100%)`,
            }}
            // className={classes.item}
             onMouseOver={() => setShow(index)}
             onMouseLeave={() => setShow(-1)}
        >
                <div className={classes.buttonContainer}>
                    {
                        !(show === index)  ? null :
                            !(userAccount?.permissions?.includes('UPDATE_ADMIN')) ? null :
                                <Tooltip title="Edit Admin">
                                    <IconButton onClick={() => {setOpenEdit(true)}}>
                                        <EditIcon sx={{ color: '#fff',}}/>
                                    </IconButton>
                                </Tooltip>
                    }
                    {
                        !(show === index)  ? null :
                            !(userAccount?.permissions?.includes('DELETE_ADMIN')) ? null :
                                <Tooltip title="Delete Admin">
                                    <IconButton onClick={() => {setIsConfirm(true)}}>
                                        <DeleteRoundedIcon sx={{ color: '#fff',}}/>
                                    </IconButton>
                                </Tooltip>
                    }
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar
                        sx={{
                            width: 60,
                            height: 60,
                            marginBottom: 2,
                            opacity: 0.8,
                            color: '#FFF',
                            backgroundColor: PRIMARY_COLOR,
                            backgroundImage: (theme) =>
                                `linear-gradient(100deg, ${alpha(SECONDARY_COLOR, 0.5)} 0%, ${alpha(
                                    SECONDARY_COLOR,
                                    1
                                )} 100%)`,
                        }}
                        src={data?.avatar}/>
                    <Typography variant={'body1'} sx={{fontWeight: 700, opacity: 0.8,}} className={classes.title}>
                        {data?.name}
                    </Typography>
                    <Typography variant={'body2'} className={classes.text}>{data?.email}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72, paddingTop: 2 }}>
                        {data?.role}
                    </Typography>
                    <Typography variant="caption" sx={{fontSize :10, opacity: 0.72, padding: 2, paddingTop: 0,
                        width: '100%', overflowWrap: 'break-word' }}>
                        {data?.permissions.toString().replace(/,/g, ', ')}
                    </Typography>
                </div>
            <ConfirmationDialog
                open={isConfirm}
                close={setIsConfirm}
                confirm={handleConfirmation}
                title={"Are you sure?"}
                message={"You may not recover the email"}
                cancelBtnText={"Cancel"}
                confirmBtnText={"Delete"}
            />
            <EditAdmin data={data} open={openEdit} setOpen={setOpenEdit}/>
        </Card>
    );
}