import React, {useEffect, useState} from 'react';
import {PRIMARY_COLOR} from "../../../constants/Colors";
import StackCustom from "../../../general_components/StackCustom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Tooltip} from "@mui/material";
import AddImage from "./AddImage";
import {WorkInProgressContext} from "../../../contexts/WorkInProgressContext";
import ConfirmationDialog from "../../../general_components/ConfirmationDialog";
import {AuthenticationContext} from "../../../contexts/AuthContext";

export default function WorkImages({item, setOpen, setOpenEdit, setTitleDate, setOpenEditId}) {

    const [imageRaw, setImageRaw] = useState('');

    const workInProgressContext = React.useContext(WorkInProgressContext),
        {handleDeleteTitle} = workInProgressContext

    const [isConfirm, setIsConfirm] = useState(false)
    const [confirmData, setConfirmData] = useState({requestType: '', workProgressId: ''})

    const handelConfirm = () => {
        handleDeleteTitle({requestType: confirmData.requestType, workProgressId: confirmData.workProgressId})
        setIsConfirm(false);
    }

    const {userAccount} = React.useContext(AuthenticationContext);

    return (
        <>
            <StackCustom
                text={item.title}
                isText={true}
                child={
                    userAccount?.permissions?.includes('UPDATE_WORK_IN_PROGRESS') ?
                        <div>
                            <Tooltip title='Delete Title'>
                                <IconButton
                                    style={{backgroundColor: 'rgba(255,255,255,0)', color: PRIMARY_COLOR, marginLeft: 6}}
                                    variant={'contained'}
                                    onClick={() => {
                                        setIsConfirm(true)
                                        setConfirmData({requestType: 'TITLE', workProgressId: item.workProgressId})
                                    }}
                                >
                                    <DeleteIcon size={16}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Edit Title'>
                                <IconButton
                                    style={{backgroundColor: 'rgba(255,255,255,0)', color: PRIMARY_COLOR, marginLeft: 6}}
                                    variant={'contained'}
                                    onClick={() => {
                                        setOpenEdit(true)
                                        setOpen(true)
                                        setTitleDate(item.title)
                                        setOpenEditId(item.workProgressId)
                                    }}
                                >
                                    <EditIcon size={16}/>
                                </IconButton>
                            </Tooltip>
                        </div> : null
                }
            />
            <AddImage
                item={item}
                imageRaw={imageRaw} setImageRaw={setImageRaw}
                imageArr={item.images}
            />
            <ConfirmationDialog
                open={isConfirm}
                close={setIsConfirm}
                confirm={handelConfirm}
                title={"Are you sure?"}
                message={"You may not recover all images."}
                cancelBtnText={"Cancel"}
                confirmBtnText={"Delete"}
            />
        </>
    );
}
