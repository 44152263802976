import {useState} from 'react';
import TextField from '@mui/material/TextField';
import {Typography} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export default function CustomTextfield(props) {

    const {onValueChange, labelName, isMultiline, placeholder, isName, isPhoneNumber, type} = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value
        if(isName){
            const re = /^[a-zA-Z\s]*$/;
            if (value === "" || re.test(value)) {
                onValueChange(value);
            }
        } else if(isPhoneNumber) {
            const totalRegex = /^[0-9]{1,11}$/;
            if(value === "" || totalRegex.test(value)){
                onValueChange(value);
            }
        } else {
            onValueChange(value);
        }
    };

    return (
        <>
            <Typography variant={'caption'} style={{lineHeight: 1}} color={'textSecondary'}>{labelName}</Typography>
            <TextField
                margin="dense"
                size={'small'}
                sx={{
                    color: '#333',
                    '& input[type=number]': {
                        '-moz-appearance': 'textfield'
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                    }
                }}
                fullWidth
                variant="outlined"
                placeholder={placeholder}
                onChange={handleChange}
                {...props}
                multiline={isMultiline}
                rows={4}
                type={showPassword ? 'text' : type}
                InputProps={type === 'password' || type === 'PASSWORD' ?{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                size={'small'}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                } : {}
                }
            />
        </>
    );
}
