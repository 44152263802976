import http from "../utilities/AxiosRequest";
import httpForm from "../utilities/AxiosFormRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class WorkProgressService {

    getWorkProgress() {
        return http.get(addTokenToURL("/admin/work-progress"));
    }

    addTitle(data) {
        return http.post("/admin/work-progress", addTokenToData(data));
    }

    updateTitle(data) {
        return http.put("/admin/work-progress", addTokenToData(data));
    }

    addImage(data) {
        return httpForm.post(addTokenToURL("/admin/work-progress"), data);
    }

    deleteTitle(data){
        if(data.requestType === 'IMAGE') {
            return http.delete(addTokenToURL("/admin/work-progress")+`&requestType=${data.requestType}&workProgressId=${data.workProgressId}&imageId=${data.imageId}`);
        } else {
            return http.delete(addTokenToURL("/admin/work-progress")+`&requestType=${data.requestType}&workProgressId=${data.workProgressId}`);
        }
    }

    changePassword(data) {
        return http.post("/admin/change-password", addTokenToData(data));
    }

}

export default new WorkProgressService();