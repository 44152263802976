import React from 'react';
import Page from "../../general_components/Page";
import StackCustom from "../../general_components/StackCustom";
import {PRIMARY_COLOR} from '../../constants/Colors';
import AddTitle from "./components/AddTitle";
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {Typography} from "@mui/material";
import {AuthenticationContext} from "../../contexts/AuthContext";
import {GalleryContext} from "../../contexts/GalleryContext";
import GallerySection from "./components/GallerySection";

export default function Gallery() {

        const [open, setOpen] = React.useState(false);
        const [openEdit, setOpenEdit] = React.useState(false);
        const [openEditId, setOpenEditId] = React.useState(null);

        const workInProgressContext = React.useContext(GalleryContext),
            {galleryList} = workInProgressContext

        const [titleDate, setTitleDate] = React.useState('');

        const {userAccount} = React.useContext(AuthenticationContext);

        return (
            <Page title="Riviera SkyGarden | Gallery" style={{paddingBottom: '6vh'}}>
                    <StackCustom
                        text="Gallery"
                        isText={true}
                        child={null
                                // userAccount?.permissions?.includes('UPDATE_WORK_IN_PROGRESS') ?
                                //     <IconButton
                                //         style={{backgroundColor: PRIMARY_COLOR, color: '#fff', marginLeft: 6}}
                                //         variant={'contained'}
                                //         onClick={() => setOpen(true)}
                                //     >
                                //             <AddRoundedIcon size={18}/>
                                //     </IconButton> : null
                        }
                    />
                    <Typography variant={'body2'}><i>*Image upload size should be upto 1MB.</i></Typography>
                    <br/>
                    {
                            galleryList.length === 0 ? null :
                                galleryList.map((item, index) => {
                                        return (
                                            <GallerySection
                                                key={index} item={item} index={index}
                                                setOpen={setOpen} setOpenEdit={setOpenEdit}
                                                setTitleDate={setTitleDate}
                                                setOpenEditId={setOpenEditId}
                                            />
                                        )
                                })
                    }
                    {/*<AddTitle*/}
                    {/*    open={open}*/}
                    {/*    handleClose={() => {*/}
                    {/*            setOpen(false)*/}
                    {/*            setOpenEdit(false)*/}
                    {/*    }}*/}
                    {/*    titleDate={titleDate} setTitleDate={setTitleDate}*/}
                    {/*    openEdit={openEdit}*/}
                    {/*    openEditId={openEditId}*/}
                    {/*/>*/}
            </Page>
        );
}

