import React, {useState} from 'react';
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {isThisWeek, isToday, isYesterday} from "../../../utilities/Others";
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../../constants/Colors";
import Label from "../../../general_components/Label";
import {makeStyles} from "@mui/styles";
import AgentService from "../../../services/AgentService";
import InterestService from "../../../services/InterestService";
import useResponsive from "../../../hooks/useResponsive";

const useStyles = makeStyles({
    root: {
        width: '100%',
        // padding: 2,
    },
    editButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    highlighted: {
        backgroundColor: SECONDARY_COLOR,
    }
})


export default function InterestList({setShowDetails, data, setItem, updateInterest })
{
    const classes = useStyles();

    const [highlight, setHighlight] = useState(-1);

    const isMobile = useResponsive('down', 'md');


    return (
        <div className={classes.root}>
            <List sx={{maxWidth: isMobile ? 'auto' : '25vw', minWidth: '25vw', maxHeight: isMobile ? '92vh' : '90vh',  overflowY: 'auto',paddingRight: 0.5, paddingLeft: 0.5}}>
                {
                    data.map((item, index) => {
                        return item.project === 'Chalet' ? null : (
                            <ListItem
                                button
                                className={classes.editButton}
                                sx={{
                                    padding: 0,
                                    paddingTop: '1vh', paddingBottom: '1vh',
                                    paddingLeft: '1vh',
                                    borderRadius: 5,
                                    backgroundColor: highlight === item.interestId ? 'action.selected' : null,
                                }}
                                onClick={() => {
                                    InterestService.updateInterest({interestId: item.interestId, isRead: true}).then(res => {
                                        if(res.data.success){
                                            updateInterest(item.interestId, {isRead: true})
                                            setHighlight(item.interestId)
                                            setShowDetails(true)
                                            setItem(item)
                                        }else {
                                            alert(res.data.error)
                                        }
                                    }).catch(e => {
                                        alert(e);
                                    })
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: highlight === item.interestId ? PRIMARY_COLOR : null}}>
                                        {index + 1}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    // className={classes.title}
                                    sx={{color: highlight === item.interestId ? PRIMARY_COLOR : null,
                                        textOverflow: 'ellipsis'}}
                                    primary={
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            {item.firstName + " " + item.lastName}
                                           <div style={{display: 'flex'}}>
                                               <div style={{fontSize: 10, borderRadius: 2, padding: '2px 5px', width: 'auto', marginRight: 10,
                                                   backgroundColor: SECONDARY_COLOR, color: '#fff'}}>{item.project || 'SkyGarden'}</div>
                                               {
                                                   item.isRead ? null :
                                                       <div style={{fontSize: 10, borderRadius: 2, padding: '2px 5px', width: 'auto', marginRight: 10,
                                                           backgroundColor: PRIMARY_COLOR, color: '#fff'}}>New</div>
                                               }
                                           </div>
                                        </div>
                                    }
                                    secondary={item.email}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </div>
    );
}
