import * as React from 'react';
import {makeStyles} from "@mui/styles";
import Lottie from "react-lottie";
import {Modal} from "@mui/material";

const useStyles = makeStyles({

});

export default function SuccessMessage({ open, close, message})
{
    const classes = useStyles();

    const handleClose = () => {
        close({open: false, message: ''})
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            disableEnforceFocus
        >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <div>
                    <Lottie
                        options={{
                            loop: true, autoplay: true,
                            animationData: require('../assets/Lottie/success.json'),
                        }}
                        height={200} width={200}
                    />
                </div>
            </div>
        </Modal>
    );
}
