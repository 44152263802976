import Main from "./main/Main";
import AuthenticationContextProvider from "./contexts/AuthContext";
import ThemeProvider from "./theme";
import ChartContextProvider from "./contexts/ChartContext";
import AgentContextProvider from "./contexts/AgentContext";
import InterestContextProvider from "./contexts/InterestContext";
import BrochureContextProvider from "./contexts/BrochureContext";

export default function App() {

  return (
      <ThemeProvider>
          <AuthenticationContextProvider>
                  <AgentContextProvider>
                      <InterestContextProvider>
                          <BrochureContextProvider>
                              <Main/>
                          </BrochureContextProvider>
                      </InterestContextProvider>
                  </AgentContextProvider>
          </AuthenticationContextProvider>
      </ThemeProvider>
  );
}

