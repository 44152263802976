import Cookies from "js-cookie";


export const getAPIEndpointUrl = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:3003';
    }
    return "https://api.skygarden.pk";
}

export const getToken = () => {
    return Cookies.get('token');
}

export const addTokenToData = (data) => {
    return {token: getToken(), ...data};
}

export const addTokenToURL = (url) => {
    return `${url}?token=${getToken()}`;
}

export const saveToken = (Token) => {
    return Token
}