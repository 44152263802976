import http from "../utilities/AxiosRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class USDService {

    getUSDRate() {
        return http.get(addTokenToURL("/admin/usd-rate"));
    }

    editUSDRate(data){
        return http.put(addTokenToURL("/admin/usd-rate"), addTokenToData(data));
    }

}

export default new USDService();
