import React from 'react';
import {Stack, Typography} from "@mui/material";

export default function StackCustom({text, child, isText, color}) {
    return (
        <Stack direction="row" alignItems="center" mb={isText ? 2 : 4}>
            <Typography variant="h5" sx={{color: color ? color : 'auto', fontWeight: 700, fontFamily: 'MontserratRegular !important',}}>
                {text}
            </Typography>
            {child}
        </Stack>
    );
}