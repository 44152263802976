import React, {useState} from 'react';
import {
    FormControl, Typography
} from "@mui/material";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const MenuProps = {
    PaperProps: {
        style: {
            //width: '100%'
            maxHeight: 140,
        },
    },
};

export default function Rights({type, data, value, setValue}) {


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setValue (
            typeof value === 'string' ? value.split(',') : value
        );

    }

    // .toUpperCase().replace(' ', '_')

    return (
        <>
            <Typography variant={'caption'} color={'textSecondary'}>
                Select Permissions
            </Typography>
            <Select
                multiple margin="dense"
                size={'small'} fullWidth
                value={value}
                required={true}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                // renderValue={(selected) => (
                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                //         {
                //             selected.map((value) => (
                //                 <Chip key={value} label={value} />
                //             ))
                //         }
                //     </Box>
                // )}
                variant="outlined"
                sx={{
                    width: '100%',
                    marginTop: 1
                }}
                MenuProps={MenuProps}
            >
                {
                    data?.map((item, index) => (
                        <MenuItem key={index} value={item.value}
                                  sx={{padding: 0, paddingLeft: 1}}
                        >
                            <Checkbox checked={value.indexOf(item.value) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))
                }
            </Select>
        </>
    );
}

