import React, {useState} from 'react';
import StackCustom from "../../../general_components/StackCustom";
import AddImage from "./AddImage";
import {Divider} from "@mui/material";
import {PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR} from "../../../constants/Colors";
export default function GallerySection({item, setOpen, setOpenEdit, setTitleDate, setOpenEditId}) {

    const [imageRaw, setImageRaw] = useState('');

    return (
        <>
            <StackCustom
                text={item.title}
                isText={true}
                child={null}
                color={
                    item.title.toLowerCase().includes('pento') ? PRIMARY_COLOR :
                    item.title.toLowerCase().includes('nexus') ? TERTIARY_COLOR : SECONDARY_COLOR
                }
            />
            <AddImage
                item={item}
                imageRaw={imageRaw} setImageRaw={setImageRaw}
                imageArr={item.images}
            />
            <br/>
            <Divider style={{height: 1}}/>
            <br/>
            <br/>
        </>
    );
}
