import React, {createContext, useEffect, useState} from 'react';
import {
    nexusFourBedData,
    nexusThreeBedData,
    pento4BedImage,
    pento5BedImage,
    skyExchangeData
} from "../pages/home/components/ChartData";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import ChartService from "../services/ChartService";
import AgentService from "../services/AgentService";
import USDService from "../services/USDService";


export const ChartContext = createContext();

export default function ChartContextProvider({children}) {

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [data, setData] = useState(null);
    const [usdRate, setUsdRate] = useState({usdRate: 1});

    const [pentoData, setPentoData] = useState([]);
    const [nexusThreeBedData, setNexusThreeBedData] = useState([]);
    const [nexusFourBedData, setNexusFourBedData] = useState([]);
    const [skyExchangeData, setSkyExchangeData] = useState([]);

    useEffect(() => {
        getFloorPlan();
        getUSDRate();
    }, []);

    const getUSDRate = () => {
        USDService.getUSDRate().then(response => {
            if(response.data.success){
                setUsdRate(response.data.data);
            }
        }).catch(() => {});
    }

    const getFloorPlan = () => {
        setOpen(true);
        ChartService.getFloorPlan().then(res => {
            const data = res.data;
            setOpen(false);
            if(data.success){
                setPentoData(data.data.pentoData);
                setNexusThreeBedData(data.data.nexusThreeBedData);
                setNexusFourBedData(data.data.nexusFourBedData);
                setSkyExchangeData(data.data.skyExchangeData);
            } else {
                alert(res.data.error);
            }
        }).catch(e => {
            // alert(e)
            setOpen(false);
        }).finally(() => {
            setOpen(false);
        })
    }

    const handleType = (selected, nexusSelected) => {
        if(selected === 'PENTO') {
            return 'pentoData'
        }
        else if(selected === 'NEXUS') {
            if (nexusSelected === '3 Bed') {
                return 'nexusThreeBedData'
            } else if (nexusSelected === '4 Bed') {
                return 'nexusFourBedData'
            }
        }
        else if (selected === 'SKYEXCHANGE') {
            return 'skyExchangeData'
        }
    }

    const updateFloorPlan = (selected, nexusSelected, value, update) => {
        if(selected === 'PENTO') {
            setPentoData(
                pentoData.map(item => {
                    if(item._id === value._id){
                        return {...item, ...update}
                    }else {
                        return item;
                    }
                }));
        }
        else if(selected === 'NEXUS') {
            if(nexusSelected === '3 Bed') {
                setNexusThreeBedData(
                    nexusThreeBedData.map(item => {
                        if(item._id === value._id){
                            return {...item, ...update}
                        }else {
                            return item;
                        }
                    }));
            }
            else if(nexusSelected === '4 Bed') {
                setNexusFourBedData(
                    nexusFourBedData.map(item => {
                        if(item._id === value._id){
                            return {...item, ...update}
                        } else {
                            return item;
                        }
                    }));
            }
        }
        else if (selected === 'SKYEXCHANGE') {
            setSkyExchangeData(
                skyExchangeData.map(item => {
                    if(item._id === value._id){
                        setOpen(false)
                        setConfirm(true);
                        setTimeout(() => {
                            setConfirm(false);
                        }, 2000);
                        return {...item, ...update}
                    }else {
                        setOpen(false)
                        return item;
                    }
                }));
            setOpen(false)
        }
        if (data !== null) {
            setData(
                data.map(item => {
                    if(item._id === value._id){
                        return {...item, ...update}
                    }else {
                        return item;
                    }
                }));
        }
    }

    const handleUpdateFloorPlan = (selected, nexusSelected, value, update) => {
        const data = {
            type: handleType(selected, nexusSelected),
            id: value._id,
            ...update
        }
        setOpen(true)
        ChartService.updateFloorPlan(data).then(res => {
            if(res.data.success){
                setOpen(false)
                setConfirm(true);
                setTimeout(() => {
                    setConfirm(false);
                }, 2000);
                updateFloorPlan(selected, nexusSelected, value, update);
            }else {
                alert(res.data.error)
                setOpen(false)
            }
        }).catch(e => {
            alert(e);
            setOpen(false)
        }).finally(() => setOpen(false))
    }


    return (
        <ChartContext.Provider value={{
            pentoData, setPentoData, nexusThreeBedData, setNexusThreeBedData,
            nexusFourBedData, setNexusFourBedData, skyExchangeData, setSkyExchangeData,
            data, setData, getFloorPlan, updateFloorPlan, handleUpdateFloorPlan, usdRate, setUsdRate
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
            <SuccessMessage open={confirm} close={setConfirm}/>
        </ChartContext.Provider>
    )
}