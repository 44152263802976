import React, {createContext, useState} from 'react';
import Loader from "../general_components/Loader";
import BrochureService from "../services/BrochureService";


export const BrochureContext = createContext();

export default function BrochureContextProvider({children}) {

    const [open, setOpen] = useState(false);

    const [brochureRequests, setBrochureRequests] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [item, setItem] = useState(null);

    const getBrochureRequests = () => {
        setOpen(true);
        BrochureService.getBrochureRequests().then(res => {
            setOpen(false);
            if(res.data.success){
                setBrochureRequests(arr => [...res.data.data]);
            }else {
                alert(res.data.error)
            }
        }).catch(e => {
            setOpen(false);
        }).finally(() => {
            setOpen(false);
        })
    }

    const updateBrochureRequest = (id, update) => {
        setOpen(true)
        if(brochureRequests.length !== 0) {
            setBrochureRequests(
                brochureRequests.map(item => {
                    if(item.requestId === id){
                        setOpen(false)
                        // setConfirm(true);
                        // setTimeout(() => {
                        //     setConfirm(false);
                        // }, 2000);
                        setItem({...item, isRead: true, ...update})
                        return {...item, isRead: true, ...update}
                    }else {
                        setOpen(false)
                        return item;
                    }
                }));
            setOpen(false)
        }
        setOpen(false)
    }

    return (
        <BrochureContext.Provider value={{
            brochureRequests, item, setItem, getBrochureRequests, updateBrochureRequest, showDetails, setShowDetails
        }}>
            {children}
            <Loader open={open} close={setOpen}/>
        </BrochureContext.Provider>
    )
}