import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {PRIMARY_COLOR, CANCEL} from "../constants/Colors";
import {Typography} from "@mui/material";

import {makeStyles} from '@mui/styles';
import SecurityIcon from '@mui/icons-material/Security';
import { Button } from '@mui/material';
import LoadingButton from "./LoadingButton";
import AdminService from "../services/AdminService";
import CustomTextfield from "./CustomTextField";

const useStyles = makeStyles((theme) => ({}));

export default function ChangePassword(props) {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if(password.length < 6) {return alert('Password should be at min 6 characters.')}
        if(password !== confirmPassword) {return alert('Passwords do not match.')}

        setLoading(true);
        AdminService.changePassword({currentPassword, password}).then(response => {
            if(response.data.success){
                alert('Password is successfully updated!');
                setOpen(false);
                setCurrentPassword('');
                setPassword('');
                setConfirmPassword('');
            }else{
                alert(response.data.error);
            }
        }).catch(error => {
            alert('Something went wrong!');
        }).finally(() => setLoading(false));
    }

    return (
        <>
            <Button fullWidth color="primary" variant="outlined" onClick={()=> setOpen(true)} style={{marginBottom: 20}}
                    startIcon={<SecurityIcon/>}
            >
                Change Password
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600'}}>Change Password</Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 10}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextfield
                            labelName={'Current Password*'} value={currentPassword} onValueChange={setCurrentPassword}
                            type={'password'} required style={{marginBottom: 15}}
                        />
                        <CustomTextfield
                            labelName={'New Password*'} value={password} onValueChange={setPassword} type={'password'} required
                            style={{marginBottom: 15}}
                        />
                        <CustomTextfield
                            labelName={'Confirm Password*'} value={confirmPassword} onValueChange={setConfirmPassword}
                            type={'password'} required style={{marginBottom: 30}}
                        />
                        <LoadingButton type={'submit'} loading={loading} text={'Update Password'} style={{color: '#fff'}}/>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}