import http from "../utilities/AxiosRequest";
import httpForm from "../utilities/AxiosFormRequest";
import {addTokenToData, addTokenToURL} from "../utilities/ServerRequestModifier";

class GalleryService {

    getGallery() {
        return http.get(addTokenToURL("/admin/gallery"));
    }

    addTitle(data) {
        return http.post("/admin/gallery", addTokenToData(data));
    }

    addImage(data) {
        return httpForm.post(addTokenToURL("/admin/gallery"), data);
    }

    deleteImage(data){
        if(data.requestType === 'IMAGE') {
            return http.delete(addTokenToURL("/admin/gallery")+`&requestType=${data.requestType}&sectionId=${data.sectionId}&imageId=${data.imageId}`);
        } else {
            return http.delete(addTokenToURL("/admin/gallery")+`&requestType=${data.requestType}&sectionId=${data.sectionId}`);
        }
    }

}

export default new GalleryService();