import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {MenuItem, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CustomTextField from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {PRIMARY_COLOR, WHITE} from "../../../constants/Colors";
import {ChartContext} from "../../../contexts/ChartContext";

export default function AddAvailability(props) {

    const {open, handleClose, value, selected, nexusSelected, status, setStatus} = props;
    const chartContext = React.useContext(ChartContext);
    const { handleUpdateFloorPlan } = chartContext


    const onSubmit = async (e) => {
        e.preventDefault();
        handleUpdateFloorPlan(selected, nexusSelected, value, {unitStatus: status});
        handleClose();
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600', flexGrow: 1}}>
                        Edit Availability
                    </Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 0}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            labelName={'Availability'} required select
                            style={{marginBottom: 25,}}
                            value={status} onValueChange={setStatus}
                        >
                            {
                                ['AVAILABLE', 'RESERVED', 'SOLD'].map((value, index) => {
                                    return <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </CustomTextField>
                        <LoadingButton
                            type={'submit'}
                            loading={false}
                            text={'Done'}
                            sx={{color: WHITE}}
                        />
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
