import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {PRIMARY_COLOR,} from "../../../constants/Colors";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import CustomTextField from "../../../general_components/CustomTextField";
import {AdminsContext} from "../../../contexts/AdminsContext"
import MenuItem from "@mui/material/MenuItem";
import {useState} from "react";
import LoadingButton from "../../../general_components/LoadingButton";
import Rights from "./Rights";

export default function AddAdmin({open, setOpen}) {

    const adminsContext = React.useContext(AdminsContext);
    const { name, setName, email, setEmail, password, setPassword,
        role, setRole, handleAddData,
        permissions, setPermissions
    } = adminsContext;

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if(password.length < 5){
            alert('Password must be at least 5 characters.');
            return;
        }
        setLoading(true);
        const data = {
            email: email, name: name,
            role: role, password: password,
            permissions: permissions
        }
        handleAddData(data)
        setOpen(false);
        setLoading(false);
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='sm'>
                <DialogTitle>
                    <Typography variant={'body1'} style={{color: PRIMARY_COLOR, fontWeight: '600'}}>Add Admin</Typography>
                </DialogTitle>
                <DialogContent style={{width: 350, paddingTop: 10}}>
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            autoFocus={true} value={name} onValueChange={setName} required
                            labelName={'Name'}
                            style={{marginBottom: 15,}}
                        />
                        <CustomTextField
                            labelName={'Email'} value={email} onValueChange={setEmail} type={'email'} required
                            style={{marginBottom: 15,}}
                        />
                        <CustomTextField labelName={'Role'} required
                                         style={{marginBottom: 15,}}
                                         select value={role} onValueChange={(value) => {
                                             setRole(value);
                                             if(value === 'SUPER_ADMIN'){
                                                 setPermissions(
                                                     [
                                                         'ADD_ADMIN', 'DELETE_ADMIN', 'EDIT_FLOOR_STATUS',
                                                         'EDIT_FLOOR_PRICE', 'ADD_AGENT', 'DELETE_AGENT',
                                                         'UPDATE_AGENT_REQUESTS', 'DELETE_INTEREST'
                                                     ]
                                                 )
                                             }else{setPermissions([])}
                                         }}
                        >
                            {
                                [{title: 'Super Admin', value: 'SUPER_ADMIN'}, {title: 'Admin', value: 'ADMIN'}].map((value, index) => {
                                    return <MenuItem key={index} value={value.value}>{value.title}</MenuItem>
                                })
                            }
                        </CustomTextField>
                        {
                            role === 'SUPER_ADMIN' || role === null ? null :
                                <div style={{marginBottom: 10}}>
                                    <Rights
                                        type={"Admin"}
                                        data={[
                                            {name: 'Add Admin', value: 'ADD_ADMIN'},
                                            {name: 'Delete Admin', value: 'DELETE_ADMIN'},
                                            {name: 'Update Admin', value: 'UPDATE_ADMIN'},
                                            {name: 'Edit Floor Status', value: 'EDIT_FLOOR_STATUS'},
                                            {name: 'Edit Floor Price', value: 'EDIT_FLOOR_PRICE'},
                                            {name: 'Add Agent', value: 'ADD_AGENT'},
                                            {name: 'Delete Agent', value: 'DELETE_AGENT'},
                                            {name: 'Update Agent', value: 'UPDATE_AGENT_REQUESTS'},
                                            {name: 'Delete Interests', value: 'DELETE_INTEREST'},
                                            {name: 'Update Work In Progress', value: 'UPDATE_WORK_IN_PROGRESS'},
                                            {name: 'Update Gallery', value: 'UPDATE_GALLERY'},
                                        ]}
                                        value={permissions}
                                        setValue={setPermissions}
                                    />
                                </div>
                        }
                        <CustomTextField
                            labelName={'Password'} value={password} onValueChange={setPassword} type={'password'} required
                            style={{marginBottom: 30}}
                        />
                        <LoadingButton type={'submit'} loading={loading} text={'Add Admin'}  style={{color:'#fff'}}/>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
