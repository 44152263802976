import React from 'react';
import {Button, ButtonGroup} from "@mui/material";
import {CANCEL, COMPLETE, PRIMARY_COLOR, PROCESS, CONFIRM} from "../../../constants/Colors";
import AgentService from "../../../services/AgentService";

export default function GroupButton({item, updateRequest}) {

    const handleButton = (status) => {
        AgentService.updateAgentRequests({requestId: item.requestId, status: status, isRead: true}).then(res => {
            if(res.data.success){
                updateRequest(item.requestId, {status: status})
            }else {
                alert(res.data.error)
            }
        }).catch(e => {
            alert(e);
        })
    }

    return (
        <ButtonGroup variant="outlined" aria-label="outlined primary button group"
                     sx={{marginLeft: 2,}}>
            <Button
                sx={{backgroundColor: item?.status === 'CANCELLED' ? CANCEL : 'transparent',
                    '&:hover': {
                        backgroundColor:  item?.status === 'CANCELLED' ? CANCEL : 'transparent',
                    },
                    color:  item?.status === 'CANCELLED' ? '#FFF' : PRIMARY_COLOR}}
                onClick={() => handleButton("CANCELLED")}>
                Cancelled
            </Button>
            <Button
                sx={{backgroundColor: item?.status === 'PENDING' ? PROCESS : 'transparent',
                    '&:hover': {
                        backgroundColor:  item?.status === 'PENDING' ? PROCESS : 'transparent',
                    },
                    color:  item?.status === 'PENDING' ? '#FFF' : PRIMARY_COLOR}}
                onClick={() => handleButton("PENDING")}>
                Pending
            </Button>
            {/*Confirmation*/}
            <Button
                sx={{backgroundColor: item?.status === 'ACCEPTED' ? CONFIRM : 'transparent',
                    '&:hover': {
                        backgroundColor:  item?.status === 'ACCEPTED' ? CONFIRM : 'transparent',
                    },
                    color:  item?.status === 'ACCEPTED' ? '#FFF' : PRIMARY_COLOR}}
                onClick={() => handleButton("ACCEPTED")}>
                Accepted
            </Button>
        </ButtonGroup>
    );
}
