import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {makeStyles} from "@mui/styles";
import LoadingButton from "./LoadingButton";
import {SECONDARY_COLOR, WHITE} from "../constants/Colors";

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'space-between',
        marginTop: 20
    },
    confirmButton: {
        marginLeft: 10,
        color: WHITE
    },
});

export default function ConfirmationDialog({ open, close, confirm, title, message, cancelBtnText, confirmBtnText}) {
    const classes = useStyles();

    const handleClose = () => {
        close(false);
    };

    const handleConfirm = () => {
        confirm();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" color={'primary'}>
                {title}
            </DialogTitle>
            <DialogContent style={{minWidth: 300}}>
                <DialogContentText id="alert-dialog-description" variant={'subtitle2'}>
                    {message}
                </DialogContentText>
                <div className={classes.buttons}>
                    <Button onClick={handleClose} fullWidth={true} variant={'contained'}
                            sx={{
                                backgroundColor: SECONDARY_COLOR ,
                                '&:hover, &:focus': {
                                    backgroundColor: SECONDARY_COLOR,
                                    boxShadow: 'none'
                                },
                                marginRight: 2,
                                color: WHITE
                        }}
                    >
                        {cancelBtnText}
                    </Button>
                    <LoadingButton type={'DELETE'} loading={false} text={confirmBtnText} onClick={handleConfirm}
                                   className={classes.confirmButton}/>
                </div>
            </DialogContent>
        </Dialog>
    );
}
