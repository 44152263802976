import React, {useEffect, useState} from 'react';
import Page from "../../general_components/Page";
import {Divider, IconButton, Stack, Typography, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import InterestList from "./components/InterestList";
import {InterestContext} from "../../contexts/InterestContext";
import InterestDetails from "./components/InterestDetails";
import StackCustom from "../../general_components/StackCustom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ConfirmationDialog from "../../general_components/ConfirmationDialog";
import {AuthenticationContext} from "../../contexts/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import useResponsive from "../../hooks/useResponsive";
import {SECONDARY_COLOR} from "../../constants/Colors";
import MobileList from "../../general_components/MobileList";

const useStyles = makeStyles({
    gridContainer: {
        display: 'flex',
        maxWidth: '100%',
        minWidth: '100%',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%'
    },
    detailsContainer: {
        maxHeight: '82vh',
        overflowY: 'auto',
        paddingLeft: 0,
        paddingRight: 0
    }
})

export default function Interest() {

    const classes = useStyles();

    const interestContext = React.useContext(InterestContext),
        {showDetails, setShowDetails, item, setItem,
            interestData, setOpenDelete, handleDelete, openDelete,
            interestId, setInterestId, getInterestsData, updateInterest} = interestContext;

    const authenticationContext = React.useContext(AuthenticationContext);
    const {userAccount} = authenticationContext;

    const isMobile = useResponsive('down', 'md');

    useEffect(() => {
        getInterestsData();
    }, []);

    return (
        <Page title="Riviera SkyGarden | Interest" style={{paddingBottom: '0vh'}}>
            <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', maxWidth: '100%', minWidth: '100%',}}>
                <div className={classes.listContainer} style={{width: isMobile ? '100%' : '40%'}}>
                    <Stack direction="row" alignItems="center" mb={0}>
                        <Typography
                            variant="h5"
                            sx={{fontWeight: 700, fontFamily: 'MontserratRegular !important',}}
                        >
                            Interested List
                        </Typography>
                        {
                            !isMobile ? null :
                                <MobileList title={'Interested List'} item={item}>
                                    <InterestList
                                        setShowDetails={setShowDetails}
                                        data={interestData}
                                        setItem={setItem}
                                        updateInterest={updateInterest}
                                    />
                                </MobileList>
                        }
                    </Stack>
                    {
                        isMobile ? null :
                            <InterestList
                                setShowDetails={setShowDetails}
                                data={interestData}
                                setItem={setItem}
                                updateInterest={updateInterest}
                            />
                    }
                </div>
                {
                    isMobile ? null :
                        <Divider
                            orientation="vertical"
                            sx={{height: '90vh',}}
                        />
                }
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', marginLeft: isMobile ? 0 : 10,
                    paddingBottom: 5, paddingLeft: isMobile ? 0 : 20, marginTop: isMobile ? 20 : 0}}>
                    {
                        !showDetails ? null :
                            <div>
                                <StackCustom
                                    text="Details"
                                    child={userAccount?.permissions?.includes('DELETE_INTEREST') ?
                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <Tooltip title={"Delete this Interest"}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setInterestId(item.interestId)
                                                            setOpenDelete(true)
                                                        }}
                                                        ml={1}
                                                    >
                                                        <DeleteRoundedIcon sx={{fontSize: 20}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div> : null
                                    }
                                />
                                <div className={classes.detailsContainer}>
                                    <InterestDetails item={item}/>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <ConfirmationDialog
                loading={false}
                open={openDelete}
                close={setOpenDelete}
                confirm={handleDelete}
                title="Confirmation"
                message="Are you sure you want to delete this?"
                cancelBtnText="Cancel"
                confirmBtnText="Delete"
            />
        </Page>
    );
}
